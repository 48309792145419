import React, { Children } from 'react'
import { Ghost, LayoutBand } from '@fs/zion-ui'
import RainbowDivider from '../../../lib/RainbowDivider'
import ResponsiveSectionBand from '../../../lib/ResponsiveSectionBand'
import WhatIsRootsTechBanner from './WhatIsRootsTechBanner'
import LibraryHeading from './LibraryHeading'
import RainbowCarouselSection from './RainbowCarouselSection'
import SponsorsCard from './SponsorsCard'

const flavors = ['blueberry', 'fire', 'apple', 'seafoam']

export default function OnDemandLibrarySections({ page, noHeading }) {
  // eslint-disable-next-line no-underscore-dangle
  const content = page?.content?.filter((item) => item.__typename !== 'VideoModule')

  return page ? (
    <>
      {!noHeading && (
        <>
          <RainbowDivider thick and full />
          <LayoutBand>
            <LibraryHeading />
          </LayoutBand>
        </>
      )}
      {Children.toArray(
        content?.map((section, ind) => {
          const flavor = flavors?.[ind % flavors.length]
          return (
            <Ghost heightEstimate={600} keepVisible>
              <ResponsiveSectionBand>
                <RainbowCarouselSection
                  overline={section.overline || ''}
                  title=""
                  {...section}
                  flavor={flavor}
                  position={ind}
                />
              </ResponsiveSectionBand>
            </Ghost>
          )
        })
      )}
      <ResponsiveSectionBand>
        <Ghost keepVisible heightEstimate={200}>
          <WhatIsRootsTechBanner />
        </Ghost>
      </ResponsiveSectionBand>
      <ResponsiveSectionBand>
        <Ghost keepVisible heightEstimate={680}>
          <SponsorsCard />
        </Ghost>
      </ResponsiveSectionBand>
    </>
  ) : null
}
