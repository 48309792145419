import React from 'react'
import { css } from '@emotion/core'
import { useHistory, Link } from '@fs/zion-router'
import { useFeatureFlag } from '@fs/zion-flags'
import { useTranslation } from 'react-i18next'
import { useUser } from '@fs/zion-user'
import { Image, MarginBox, Row, useContainerWidth, useMenuOverlay, colors, useTheme } from '@fs/zion-ui'
import { boolAttr } from '@fs/zion-frontend-friends'
import { i18n } from '@fs/zion-locale'
import Menu from './Menu'
import { rootsTechByFamilySearchLightSvg, rootsTechByFamilySearchSvg } from '../../images'
import SignInButton from './SignInButton'
import { MiniEventsMenuOverlay } from './MiniEventsMenu'
import { RootstechEventMenuOverlay } from './RootstechEventMenu'
import { WatchVideosMenuOverlay } from './WatchVideosMenu'
import { NavHelpButton, NavLanguageButton, NavAvatarButton } from './NavButtonHack'
import SearchButton from './SearchButton'
import { flavors, menuNavConfig } from '../../config'
import { useNavBarItems } from '../../hooks'
import { getIsComplexSite } from '../../lib/helpers/menuHelper'
import { EnvironmentBadge } from '../../lib'

const bodyCss = `
  color: ${colors.gray100};
  font-size: var(--text-size-b);
  height: 40px;
  display: flex;
  align-items: center;
  padding: 0;
  text-transform: none;
  transition: color 0.2s ease;
  position: relative;
  min-width: unset;

  &::after {
    content: '';
    height: 4px;
    width: 0;
    background: ${flavors.blueberry.solid};
    opacity: 0.5;
    position: absolute;
    bottom: -4px;
    border-radius: 4px;
    left: 0;
    right: 0;
    margin: 0 auto;
    transition: 0.1s;
  }
`

const hoverFocusCss = `
  color: ${colors.gray100};
  background: none;
  &::after {
    width: 50%;
  }
`

const activeCss = `
  border: none;
  color: ${colors.gray100};
  &::after {
    width: 100%;
    opacity: 1;
  }
`

const navMenuButtonCss = css`
  background: none;
  border: 0;
  ${bodyCss};

  :hover,
  :focus {
    ${hoverFocusCss}
  }
  &[active] {
    ${activeCss}
  }
`
export const NavButton = ({ children, linkName, onClick, active, ...props }) => {
  return (
    <button
      type="button"
      css={navMenuButtonCss}
      onClick={onClick}
      data-link-name={linkName}
      active={boolAttr(active)}
      {...props}
    >
      {children}
    </button>
  )
}

const NavRootsTechEventButton = ({ dense, removeFiller }) => {
  const navItems = useNavBarItems()
  const [t] = useTranslation()
  const overlay = useMenuOverlay()

  if (!getIsComplexSite() && !navItems.some((item) => item.includes('eventMenu'))) {
    return null
  }

  return (
    <>
      <NavButton
        data-testid="tab-rootstech-event"
        active={overlay.isOpen}
        linkName="Nav: RootsTech 2023"
        onClick={overlay.handleClick}
      >
        {t('navigation.rootstech-event.label', 'RootsTech Event')}
      </NavButton>
      {(overlay.isOpen || overlay.transitioning) && (
        <RootstechEventMenuOverlay {...overlay} dense={dense} removeFiller={removeFiller} />
      )}
    </>
  )
}

const NavWatchVideosButton = ({ dense, removeFiller }) => {
  const navItems = useNavBarItems()
  const [t] = useTranslation()
  const overlay = useMenuOverlay()

  if (!getIsComplexSite() && !navItems.some((item) => item.includes('menu-watchVideo'))) {
    return null
  }

  return (
    <>
      <NavButton
        data-testid="tab-watch-videos"
        linkName="Nav: Watch Videos"
        onClick={overlay.handleClick}
        active={overlay.isOpen}
      >
        {t('navigation.watch-videos.label', 'Watch Videos')}
      </NavButton>
      {(overlay.isOpen || overlay.transitioning) && (
        <WatchVideosMenuOverlay dense={dense} {...overlay} removeFiller={removeFiller} />
      )}
    </>
  )
}

const NavMiniEventsButton = ({ dense, removeFiller }) => {
  const navItems = useNavBarItems()
  const overlay = useMenuOverlay()
  const primaryText = 'Mini Events'
  const testId = 'mini-events'

  if (!getIsComplexSite() && !navItems.some((item) => item.includes('menu-mini-events'))) {
    return null
  }

  return (
    <>
      <NavButton
        data-testid={`tab-${testId}`}
        linkName={`Nav: ${primaryText}`}
        onClick={overlay.handleClick}
        active={overlay.isOpen}
      >
        {primaryText}
      </NavButton>
      {(overlay.isOpen || overlay.transitioning) && (
        <MiniEventsMenuOverlay dense={dense} {...overlay} removeFiller={removeFiller} />
      )}
    </>
  )
}

const NavRootsTechLogoLink = () => {
  const [t] = useTranslation()
  const theme = useTheme()

  return (
    <Link data-testid="rootstech-logo" to="/" aria-label={t('navigation.home.label', 'Home')} linkName="Nav: Logo">
      <Image
        src={theme === 'night' ? rootsTechByFamilySearchSvg : rootsTechByFamilySearchLightSvg}
        alt={t('rootstech.image', 'RootsTech by FamilySearch Logo')}
        aria-hidden
        style={{ padding: '2px 0', height: '48px' }}
      />
    </Link>
  )
}

const NavExpoHallLink = () => {
  const [t] = useTranslation()
  const history = useHistory()
  const primaryText = t('navigation.expo-hall.label', 'Home')

  return (
    <NavButton
      data-testid="tab-expo-hall"
      linkName={`Nav: ${primaryText}`}
      onClick={() => {
        history.push('/expohall/')
      }}
    >
      {primaryText}
    </NavButton>
  )
}

export default function NavBar() {
  const user = useUser()
  const atWidth = useContainerWidth()
  const isMdWidth = atWidth({ xxl: false, default: true })
  const userLocale = i18n.language
  const marginSize = 'xxs'
  const needsToLogin = !user.signedIn && !user.userLoading
  const loggedIn = user.signedIn && !user.userLoading
  const denseMenus = false
  const removeFiller = true
  const siteExperienceFlag = useFeatureFlag('rootstech_site_experience')
  const showExpoHall =
    siteExperienceFlag.treatment === 'conference' && !menuNavConfig.expoHallHiddenLocales.includes(userLocale)

  return (
    <nav>
      <MarginBox marginX={marginSize} marginY={marginSize}>
        <Row alignY="middle">
          <Row gutters="nano">
            <NavRootsTechLogoLink />
            <EnvironmentBadge alignY="bottom" />
          </Row>
          <div growX />
          {!isMdWidth && <NavRootsTechEventButton dense={denseMenus} removeFiller={removeFiller} />}
          {!showExpoHall && !isMdWidth && userLocale === 'en' && (
            <NavMiniEventsButton dense={denseMenus} removeFiller={removeFiller} />
          )}
          {showExpoHall && !isMdWidth && <NavExpoHallLink />}
          {!isMdWidth && <NavWatchVideosButton dense={denseMenus} removeFiller={removeFiller} />}
          <SearchButton />
          {!isMdWidth && <NavLanguageButton />}
          {!isMdWidth && <NavHelpButton />}
          {isMdWidth && <Menu dense={denseMenus} showNavItems={isMdWidth} />}
          {!isMdWidth && loggedIn && <NavAvatarButton user={user} dense={denseMenus} />}
          {!isMdWidth && needsToLogin && <SignInButton linkName="Nav: Sign In" />}
        </Row>
      </MarginBox>
    </nav>
  )
}
