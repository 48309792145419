import { colors } from '@fs/zion-ui'

const themeColors = {
  default: [colors.gray100, colors.gray60],
  complementary: [colors.gray00, colors.gray40],
}

export const getColors = (complementary) => {
  if (!complementary) return themeColors.default
  return themeColors.complementary
}
