import React, { StrictMode, Suspense } from 'react'
import { Global, css } from '@emotion/core'
import ReactDOM from 'react-dom'
import '@fs/react-scripts/polyfills'
import * as Sentry from '@sentry/react'
import { Router } from '@fs/zion-router'
import { sentryDSN, mergeSentryConfig, appPath } from '@fs/zion-config'
import { FeatureFlagsProvider } from '@fs/zion-flags'
import { setAppLocales } from '@fs/zion-locale'
import { colors } from '@fs/zion-ui'
import App from './App'
import * as serviceWorker from './serviceWorker'
import './locales'
import RootsTechRoot from './RootsTechRoot'
import { languageConfig } from './config'
import { LoadingSpinner } from './lib'

setAppLocales(languageConfig.additionalLangs)

// Enable local feature flag development using the file "dev.flags.js"
// If that file exports anything truthy it will initialize feature flags in localhost mode
// and use the flags defined in that file, instead of flags defined in split.
const mockFlags = process.env.NODE_ENV === 'development' ? import('./dev.flags').then((m) => m.default) : undefined
// import { RTCProvider } from './state/RTCStore'

// initialize Sentry for the app
if (sentryDSN) {
  // pass Sentry overrides to mergeSentryConfig
  Sentry.init(
    mergeSentryConfig({
      dsn: sentryDSN,
      // This sets the sample rate to be 10%. You may want this to be 100% while
      // in development and sample at a lower rate in production
      replaysSessionSampleRate: 0.1,
      // If the entire session is not sampled, use the below sample rate to sample
      // sessions when an error occurs.
      replaysOnErrorSampleRate: 1.0,

      integrations: [
        new Sentry.Replay({
          // Additional SDK configuration goes in here, for example:
          maskAllText: true,
          blockAllMedia: true,
        }),
      ],
      ignoreErrors: ['AbortError', 'SecurityError: The operation is insecure.'],
      denyUrls: [/bitmovin-player/],
      sampleRate: 0.2,
    })
  )
}

// This is where you pass data from the server to the client using the SERVER_DATA global.
// Here we pass the mounted app path from the server to the client.
const base = appPath ? new URL(appPath).pathname : ''

// makes the background dark before the app loads. Setting the theme is the "correct" way to do it once the app loads
const htmlOverrideCss = css`
  html {
    background-color: ${colors.gray00};
  }
`

const FrontierRoot = () => {
  return (
    <>
      <Global styles={htmlOverrideCss} />
      <StrictMode>
        <Suspense fallback={<LoadingSpinner />}>
          <FeatureFlagsProvider mockFlags={mockFlags} enableRealtime={false}>
            <Router basename={base}>
              <RootsTechRoot>
                <App />
              </RootsTechRoot>
            </Router>
          </FeatureFlagsProvider>
        </Suspense>
      </StrictMode>
    </>
  )
}

ReactDOM.render(<FrontierRoot />, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
