import SeoTwitter from './SeoTwitter'
import SeoOpenGraph from './SeoOpenGraph'

const Seo = `
  ${SeoTwitter}
  ${SeoOpenGraph}
  fragment Seo on Seo {
    twitter {
      ...SeoTwitter
    }
    openGraph {
      ...SeoOpenGraph
    }
    canonicalLink
    description
    keywords
    noFollowRobot
    noImageIndexRobot
    noIndexRobot
    title
  }
`

export default Seo
