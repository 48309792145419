const registrationConfig = {
  defaultPromoVideos: {
    de: 'XdIxhuY_zlg',
    en: 'zzGzbHoIFhk',
    es: 'tCxu5nMw1sU',
    fr: 'p_hqf6USeWk',
    it: '7mA31fxTmAg',
    ja: 'nGqPJGTATV4',
    ko: 'UT4m5iFnBgE',
    mn: 'zzGzbHoIFhk', // english for now since there is no mn translation
    pt: 'IICNZSgN528',
    ru: 'csiutIuVFz8',
    zh: 'T03P9Z3aJyw',
    'zh-hans': 'ZGrheJKq3w8',
  },
  discoveryPromoVideos: {
    de: 'XdIxhuY_zlg',
    en: 'CKikmJYJFvY',
    es: 'tCxu5nMw1sU',
    fr: 'p_hqf6USeWk',
    it: '7mA31fxTmAg',
    ja: 'nGqPJGTATV4',
    ko: 'UT4m5iFnBgE',
    mn: 'zzGzbHoIFhk', // english for now since there is no mn translation
    pt: 'IICNZSgN528',
    ru: 'csiutIuVFz8',
    zh: 'T03P9Z3aJyw',
    'zh-hans': 'ZGrheJKq3w8',
  },
  attendeePricing: {
    promo: {
      threeDay: 99,
      oneDay: 69,
    },
    standard: {
      threeDay: 129,
      oneDay: 79,
    },
  },
}

export default registrationConfig
