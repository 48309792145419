import { getEnv } from './urlHelpers'

export default function getCommunityChatId() {
  const env = getEnv()
  const chatGroup = {
    integration: 'fs_rootstech:pgfvx53-20220302_conference_chatsvr_familysearch_org',
    beta: 'fs_rootstech:rqnf7rj-20231212101202_J9HB',
    www: 'fs_rootstech:rskd98v-20230224185751_prod-J9HB',
  }
  return chatGroup[env]
}
