import React, { createContext, useContext } from 'react'
import useChatApproval from '../../hooks/useChatApproval'

const ChatContext = createContext()
export const useChatContext = () => useContext(ChatContext)

export const ChatProvider = ({ children }) => {
  const chatApproval = useChatApproval()

  const value = React.useMemo(() => ({ ...chatApproval }), [chatApproval])

  return <ChatContext.Provider value={value}>{children}</ChatContext.Provider>
}
