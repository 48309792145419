import React, { Children } from 'react'
import '@fs/react-multi-carousel/lib/styles.css'
import ReactMultiCarousel from '@fs/react-multi-carousel'
import { css } from '@emotion/core'
import PropTypes from 'prop-types'
import Navigation from './Navigation'
import { isSessionDetail, isConferencePromo } from '../helpers'
import StructuredDataSingleViewCarousel from './StructuredDataSingleViewCarousel'
import { flavors } from '../../config'

const containerCss = css`
  position: relative;

  & ul > li {
    margin-inline: 0;
  }
`

// This is required by the component, but we kind of high jack it since we only show one at a time; it doesn't matter that much
const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 1,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1213 },
    items: 1,
  },
  tablet: {
    breakpoint: { max: 1213, min: 750 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 750, min: 0 },
    items: 1,
  },
}

const generateCarouselStructuredData = (children) => {
  const childrenArray = Children.toArray(children)
  if (childrenArray.length === 0) return null

  const validItems = childrenArray.filter((child) => {
    return isSessionDetail(child) || isConferencePromo(child)
  })

  if (validItems.length === 0) return null

  const carouselItems = validItems.map((child, index) => {
    const promo = child.props?.promo || {}
    const title = promo.title || ''
    const url = promo.url || ''
    const image = promo.image?.attributes?.publicUrl || ''

    return {
      '@type': 'ListItem',
      position: index + 1,
      name: title,
      url,
      image,
    }
  })

  return {
    '@context': 'https://schema.org',
    '@type': 'ItemList',
    itemListElement: carouselItems,
  }
}

/**
 * A carousel that displays one item at a time.
 */
export default function SingleViewCarousel({
  flavor = flavors.default,
  autoplay = false,
  autoplaySpeed = 5000,
  children,
  'data-testid': dataTestId = 'single-view-carousel',
  infinite = false,
}) {
  const carouselStructuredData = generateCarouselStructuredData(children)

  return (
    <div css={containerCss}>
      <StructuredDataSingleViewCarousel data={carouselStructuredData} />
      <ReactMultiCarousel
        data-testid={dataTestId}
        draggable
        autoPlay={autoplay}
        autoPlaySpeed={autoplaySpeed}
        responsive={responsive}
        arrows={false}
        showDots={false}
        infinite={infinite}
        renderButtonGroupOutside
        customButtonGroup={<Navigation flavor={flavor} infinite={infinite} />}
      >
        {children}
      </ReactMultiCarousel>
    </div>
  )
}

SingleViewCarousel.propTypes = {
  flavor: PropTypes.string,
  autoplay: PropTypes.bool,
  autoplaySpeed: PropTypes.number,
  children: PropTypes.node.isRequired,
  'data-testid': PropTypes.string,
  infinite: PropTypes.bool,
}
