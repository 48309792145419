import React, { Children } from 'react'
import { Separator, FlowGrid, useContainerWidth, Subheading, H2 } from '@fs/zion-ui'
import BorderPromoCard from './card/BorderPromoCard'
import { getCustomImage } from './helpers'

const dailyScheduleFlavors = ['fire', 'mango', 'blueberry']
const onlineScheduleFlavors = ['apple', 'seafoam', 'dusk']

export default function DailyScheduleCards({ data, isOnline = false }) {
  const atWidth = useContainerWidth()
  const itemCount = data.items?.length || 1
  const columnCountLg = itemCount > 2 ? 3 : itemCount
  const columnCountMd = itemCount > 1 ? 2 : itemCount

  return (
    <>
      <H2 supplementary centered>
        {data.title}
        <Subheading>{data.description}</Subheading>
      </H2>
      <Separator />
      {data.items && (
        <FlowGrid columnCount={atWidth({ default: 1, md: columnCountMd, lg: columnCountLg })}>
          {Children.toArray(
            data.items.map((item, i) => {
              return (
                <BorderPromoCard
                  {...item}
                  heading={item.title}
                  flavor={isOnline ? onlineScheduleFlavors[i] : dailyScheduleFlavors[i]}
                  image={getCustomImage(item)}
                  noScrim
                  as="h3"
                />
              )
            })
          )}
        </FlowGrid>
      )}
    </>
  )
}
