import { css } from '@emotion/core'
import React from 'react'
import { SocialChat } from '@fs/zion-icon'
import { IconButton, ListItem, MenuOverlay, useMenuOverlay } from '@fs/zion-ui'
import { useTranslation } from 'react-i18next'
import { useFeatureFlag } from '@fs/zion-flags'
import { useRouteMatch } from '@fs/zion-router'
import { boolAttr } from '@fs/zion-frontend-friends'
import AUAChatButton from '../AUAChatButton'
import PillButton from '../PillButton'
import { useAnonCanAddPersonalData, useIsMdWidth } from '../../../hooks'
import { floatingChatConfig } from '../../../config'
import { useChatContext } from '../../../providers'
import ChatButton from '../../../components/header/ChatButton'

const floatingCss = css`
  position: fixed;
  inset-inline-end: 20px;
  bottom: 40px;
  z-index: 1;
  white-space: nowrap;
`

export const FloatingChatButtonOverlay = ({ ...overlay }) => {
  const [t] = useTranslation()
  return (
    <MenuOverlay {...overlay}>
      <AUAChatButton
        data-testid="floating-chatButton-askQuestion"
        linkName="FloatingChatButton: Ask a Question"
        asComponent={ListItem}
        primaryText={t('chat.floating-buton.ask-question', 'Ask a Question')}
        dense
      />
      <ChatButton
        data-testid="floating-chatButton-openChats"
        linkName="FloatingChatButton: Open all Chats"
        asComponent={ListItem}
        primaryText={t('chat.floating-buton.open-chats', 'Open all Chats')}
        dense
      />
    </MenuOverlay>
  )
}

const FloatingChatButton = () => {
  const [t] = useTranslation()
  const chatFlag = useFeatureFlag('rootstech_chat')
  const chatMenuFlag = useFeatureFlag('rootstech_chat_menu')
  const isMdWidth = useIsMdWidth()
  const blacklistMatch = useRouteMatch(floatingChatConfig.ROUTE_BLACKLIST)
  const { isChatApproved } = useChatContext()
  const overlay = useMenuOverlay()

  const Tag = isMdWidth ? IconButton : PillButton
  const canUsePersonalData = useAnonCanAddPersonalData()
  if (blacklistMatch || !isChatApproved || !canUsePersonalData) {
    return null
  }

  return (
    chatFlag.isOn && (
      <div css={floatingCss} is-md-width={boolAttr(isMdWidth)}>
        {chatMenuFlag.isOn ? (
          <Tag
            color="blue"
            data-testid="floating-chat-button"
            linkName="Chat: Floating Button"
            Icon={SocialChat}
            emphasis="high"
            size={isMdWidth ? 'md' : 'lg'}
            onClick={overlay.handleClick}
            active={overlay.isOpen}
          >
            {t('chat.button', 'Chat')}
          </Tag>
        ) : (
          <AUAChatButton
            asComponent={isMdWidth ? IconButton : PillButton}
            color="blue"
            Icon={SocialChat}
            emphasis="high"
            size={isMdWidth ? 'md' : 'lg'}
          >
            {t('chat.button', 'Chat')}
          </AUAChatButton>
        )}
        {(overlay.isOpen || overlay.transitioning) && <FloatingChatButtonOverlay {...overlay} />}
      </div>
    )
  )
}

export default FloatingChatButton
