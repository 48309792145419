import React from 'react'
import { Ellipsis, usePageWidth, Row, Image, Paragraph, Card as ZionCard, H3, Avatar } from '@fs/zion-ui'
import { removeHtml } from '@fs/zion-frontend-friends'
import { getRelativePath, isExternalUrl } from '../helpers'
import NewCard from './NewCard'
import { placeholderSeafoamJpg } from '../../images'

const scrubText = (text) => {
  return removeHtml(text)
    ?.replace(/&nbsp;/g, ' ')
    .replace('&amp;', '&')
}

export const CardListItem = ({ heading, image, description, to, external, linkName }) => {
  const isSession = to?.includes('/session/')
  const isSpeaker = to?.includes('/speakers/')
  return (
    <ZionCard
      to={to}
      external={external}
      target={external ? '_blank' : undefined}
      linkName={linkName ?? `Card: ${heading}`}
      margins="none"
      backgroundColor="none"
      edges="none"
    >
      <Row>
        {isSpeaker && <Avatar size="md" src={image} noShrinkX />}
        {!isSpeaker && (
          <Image
            noShrinkX
            noGrowY
            alt=""
            src={image}
            style={{ aspectRatio: isSession ? '16/9' : '3/2', width: 100, borderRadius: 10 }}
            loading="lazy"
          />
        )}
        <div>
          <H3 size="H6">{heading}</H3>
          <Paragraph size="sm" secondary>
            <Ellipsis lineClamp={2} noTooltip>
              {scrubText(description)}
            </Ellipsis>
          </Paragraph>
        </div>
      </Row>
    </ZionCard>
  )
}

export default function Card({
  image = placeholderSeafoamJpg,
  heading,
  description,
  // Badges/Buttons
  contentLocale,
  conferenceYear,
  duration,
  startTimestamp,
  isLive,
  workflowState,
  // ----
  contentId,
  isPlaylistable,
  isInPlaylist,
  noCrop = false,
  'data-testid': dataTestId,
  to: url,
  percentageWatched,
  // linkPage,
  ...props
}) {
  const atWidth = usePageWidth()
  const isMdWidth = atWidth({ default: true, xl: false })
  const external = isExternalUrl(url)
  const to = external ? url : getRelativePath(url)
  const scrubbedHeading = scrubText(heading)

  if (!heading) {
    return null
  }
  if (isMdWidth) {
    return (
      <CardListItem image={image} heading={scrubbedHeading} description={description} to={to} external={external} />
    )
  }

  return (
    <NewCard
      contentLocale={contentLocale}
      data-testid={dataTestId}
      description={description}
      duration={duration}
      external={external}
      heading={scrubbedHeading}
      image={image}
      isInPlaylist={isInPlaylist}
      isLive={isLive}
      isPlaylistable={isPlaylistable}
      workflowState={workflowState}
      noCrop={noCrop}
      contentId={contentId}
      startTimestamp={startTimestamp}
      to={to}
      conferenceYear={conferenceYear}
      percentageWatched={percentageWatched}
      {...props}
    />
  )
}
