import React from 'react'
import { css } from '@emotion/core'
import { boolAttr } from '@fs/zion-frontend-friends'
import PropTypes from 'prop-types'
import { flavors, validFlavors } from '../config'

const rainbowDividerCss = css`
  height: 1px;
  margin: 4px 28px;

  background: var(--gradient);

  &[full] {
    margin: 0;
  }
  &[thick] {
    height: 2px;
  }
  &[thicker] {
    height: 4px;
  }
`

export default function RainbowDivider({ thicker, thick, and, full, of, flavor = flavors.default, ...props }) {
  return (
    <div
      css={rainbowDividerCss}
      {...props}
      full={boolAttr(full)}
      thick={boolAttr(thick)}
      thicker={boolAttr(thicker)}
      style={{ '--gradient': flavors[flavor].gradient }}
      flavor={flavor}
      data-testid="divider"
    />
  )
}

RainbowDivider.propTypes = {
  full: PropTypes.bool,
  thick: PropTypes.bool,
  flavor: PropTypes.oneOf(validFlavors),
}
