import React from 'react'
import { Usabilla, useUsabilla } from '@fs/zion-usabilla'
import { usabillaConfig } from '../../../config'
import { rootsTechLogoWhitePng } from '../../../images'
import { useFeedback } from './helpers'

export const FeedbackButton = ({ as: Tag, ...props }) => {
  const { feedbackUrl: url } = useFeedback()
  const { open } = useUsabilla({ url })

  const onOpen = async () => {
    const wasAbleToOpen = await open()
    if (!wasAbleToOpen) {
      // TODO: handle the case where the user's ad-blocker prevented the usabilla modal from opening
    }
  }

  if (Tag) {
    return <Tag {...props} onClick={onOpen} />
  }

  return <Usabilla url={url} hideOnMobile logo={rootsTechLogoWhitePng} localeMap={usabillaConfig.localeMap} />
}
