import { gql } from '@apollo/client'
import { brightspotConfig } from '../../config'
import { escapeBackslash } from '../helpers'
import { ImageDetail } from './fragments/ImageDetail'

const { defaults } = brightspotConfig.query
const { imageDetailImage } = brightspotConfig.imageSizes

export function getImage() {
  return gql(`
    ${ImageDetail}
    query ImageDetail(
      $id: ID,
      $imageDetail_crop: Boolean = false,
      $imageDetail_height: Int = ${defaults.imageSize},
      $imageDetail_width: Int = ${defaults.imageSize}) {
      ImageDetail(id: $id) {
        ...ImageDetail
      }
    }
  `)
}

function mutateImage() {
  return gql(`
    ${ImageDetail}
    mutation ImageMutation(
      $target: MutationTargetInput,
      $altText: String,
      $contentLocale: String,
      $creators: [ID],
      $exhibitors: [ID],
      $fileUrl: String,
      $source: String,
      $imageDetail_crop: Boolean = false,
      $imageDetail_height: Int = ${defaults.imageSize},
      $imageDetail_width: Int = ${defaults.imageSize}) {
      ImageMutation(
        _target: $target, 
        altText: $altText, 
        contentLocale: $contentLocale, 
        creators: $creators, 
        exhibitors: $exhibitors, 
        fileUrl: $fileUrl, 
        source: $source) {
        result {
          ...ImageDetail
        }
      }
    }
  `)
}

function deleteImageMutation() {
  return gql(`
    ${ImageDetail}
    mutation ImageMutation(
      $target: MutationTargetInput,
      $workflow: WorkflowInput = { action: DELETE },
      $imageDetail_crop: Boolean = false,
      $imageDetail_height: Int = ${defaults.imageSize},
      $imageDetail_width: Int = ${defaults.imageSize}) {
      ImageMutation(
        _target: $target, 
        _workflow: $workflow) {
        result {
          ...ImageDetail
        }
      }
    }
  `)
}

export async function createImage(client, image) {
  const variables = {
    altText: escapeBackslash(image.alt),
    fileUrl: image.fileUrl,
    source: image.source,
    ...imageDetailImage,
  }
  if (image.creatorId) {
    variables.creators = image.creatorId
  }
  if (image.exhibitorId) {
    variables.exhibitors = image.exhibitorId
  }
  // If we have a session id we are doing an update.  Otherwise, it is a create
  if (image?.id) {
    variables.target = { id: image.id, locale: image.locale }
  } else {
    variables.contentLocale = image?.locale ? image.locale : ''
  }

  const response = await client.mutate({ mutation: mutateImage(), variables })
  return response?.data?.ImageMutation?.result
}

export async function updateImage(client, image) {
  return createImage(client, image)
}

export async function deleteImage(client, image) {
  const variables = {
    target: { id: image.id, locale: image.locale },
    ...imageDetailImage,
  }
  const response = await client.mutate({ mutation: deleteImageMutation(), variables })
  return response?.data?.ImageMutation?.result
}
