import { imageConfig } from '../../config'

export default function getCustomImage(promo, placeholder) {
  const image =
    promo?.image?.custom?.image?.src ?? // Images from lists on a one-off page
    promo?.thumbnail?.custom?.image?.src ?? // might work for search results that include a series, but I think this should go through Promo. If we do, I think we can combine some of these
    promo?.custom?.image?.src ?? // booth graphics, we pass in "graphics."
    promo?.video?.thumbnail?.custom?.image?.src ?? // Session Detail to get video thumbnail
    promo?.promo?.image?.custom?.image?.src ?? // Searches with a series in the list
    promo?.creators?.[0]?.profileImage?.custom?.image?.src ?? // creator thumbnail on session page
    promo?.profileImage?.custom?.image?.src ?? // Creator detail
    promo?.logo?.custom?.image?.src ?? // Logo inside of a booth
    promo?.imageDetail?.custom?.image?.src ??
    promo?.imgSrc ??
    promo?.thumbnail?.attributes?.src ??
    promo?.attributes?.publicUrl ??
    (placeholder ? imageConfig[placeholder] : imageConfig.defaultPlaceholder)

  return image
}
