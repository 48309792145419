import { format } from '@fs/zion-locale/date-fns'

const legalConfig = {
  // NOTE: Remember Date is zero based for the month.
  termsOfUseDate: format(new Date(2023, 8, 1), 'yyyy-MM-dd'),
  ruTermsOfUseDate: format(new Date(2024, 9, 9), 'yyyy-MM-dd'),
  privacyNoticeDate: format(new Date(2021, 3, 6), 'yyyy-MM-dd'),
}

export default legalConfig
