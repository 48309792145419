import React, { Children } from 'react'
import '@fs/react-multi-carousel/lib/styles.css'
import { css } from '@emotion/core'
import { boolAttr } from '@fs/zion-frontend-friends'
import Arrow from './Arrow'
import Dot from './Dot'
import { useIsMdWidth } from '../../hooks'

const navigationCss = css`
  --dot-diameter: 8px;
  --dot-margin-block-start: calc(var(--dot-diameter) * 3);
  --dot-content-area: calc(var(--dot-diameter) + var(--dot-margin-block-start));

  --children-content-area: calc(100% - var(--dot-content-area));

  // We don't have access to the button size, so we estimate it
  --estimated-button-size: 42px;
`
const arrowsCss = css`
  position: absolute;
  display: flex;
  justify-content: space-between;
  pointer-events: none;

  // put the buttons in the middle
  top: calc(var(--children-content-area) / 2 - var(--estimated-button-size) / 2);

  inset-inline-start: -2%;
  --content-width: 105%;
  width: var(--content-width);

  &[is-mobile] {
    top: calc(var(--children-content-area) / 4 - var(--estimated-button-size) / 2);
  }
`
const dotsCss = css`
  width: fit-content;
  margin: auto;
  margin-block-start: var(--dot-margin-block-start);
  display: flex;
  gap: 8px;
`

const Navigation = ({ next, previous, goToSlide, carouselState, flavor, infinite }) => {
  const isMobile = useIsMdWidth('md')
  const { currentSlide, totalItems } = carouselState

  // when the carousel is infinite, it adds duplicate slides at the beginning and end
  const isInfinite = infinite && totalItems > 1
  const firstSlideIndex = isInfinite ? 2 : 0
  const lastSlideIndex = totalItems - (isInfinite ? 3 : 1)
  const isStart = currentSlide === firstSlideIndex
  const isEnd = currentSlide === lastSlideIndex

  const onDotClick = (index) => {
    goToSlide(index)
  }

  const onNext = () => {
    if (isEnd && isInfinite) {
      goToSlide(firstSlideIndex)
    } else {
      next()
    }
  }

  const onPrevious = () => {
    if (isStart && isInfinite) {
      goToSlide(lastSlideIndex)
    } else {
      previous()
    }
  }

  const isBeginning = isInfinite || !isStart
  const isFinish = isInfinite || !isEnd
  return (
    <div css={navigationCss}>
      <div css={arrowsCss} is-mobile={boolAttr(isMobile)}>
        {/* this div is necessary to keep the forward button on the right */}
        <div>{isBeginning && <Arrow direction="backward" flavor={flavor} onClick={onPrevious} />}</div>
        {isFinish && <Arrow direction="forward" flavor={flavor} onClick={onNext} />}
      </div>

      {totalItems > 1 && (
        <div css={dotsCss}>
          {Children.toArray(
            // if there are 10 slides, we want 10 dots, not 9
            [...Array(lastSlideIndex + 1)]
              .map((_, index) => {
                if (isInfinite && (index === 0 || index === 1)) {
                  return null
                }
                return <Dot active={index === currentSlide} onClick={() => onDotClick(index)} index={index} />
              })
              .filter(Boolean)
          )}
        </div>
      )}
    </div>
  )
}

export default Navigation
