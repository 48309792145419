import React from 'react'
import { Separator, Row, Divider, H2, Overline, Subheading, Paragraph } from '@fs/zion-ui'
import RainbowDivider from '../RainbowDivider'
import { TopicTag } from '../search'
import SafeRichText from '../SafeRichText'

export default function SectionHeading({
  overline,
  heading,
  subHeading,
  description,
  actions,
  flavor = 'none',
  size = 'H3',
  tags,
  centered,
  ...props
}) {
  return heading ? (
    <>
      <Row alignY="bottom" guttersY="nano" wrap>
        <div growX>
          <H2 supplementary size={size} data-testid="section-title" centered={centered} {...props}>
            <Overline>{overline}</Overline>
            {heading}
            <Subheading>{subHeading}</Subheading>
          </H2>
        </div>
        <div noShrinkX>{actions}</div>
      </Row>
      <Separator size="xxs" />
      {tags?.length > 0 && (
        <Row>
          {tags.map((tag) => (
            <TopicTag tag={tag} key={tag} />
          ))}
        </Row>
      )}
      {description && (
        <>
          <SafeRichText as={Paragraph} size="sm" dirtyInput={description} />
          <Separator size="xs" />
        </>
      )}
      {flavor !== 'none' && (
        <>
          {flavor === 'flavorless' ? <Divider /> : <RainbowDivider thick and full of flavor={flavor} />}
          <Separator size="xs" />
        </>
      )}
    </>
  ) : null
}
