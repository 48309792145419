import React, { Children } from 'react'
import Card from './Card'
import { SectionHeading } from '../section-card'
import { getIsPlaylistable } from '../../hooks'
import { getCreatorsNames, getIsLive, getCustomImage, getPercentageWatched, getDuration } from '../helpers'
import CardGrid from './CardGrid'
// import BS_OBJECT_TYPES from '../../api/brightspot/objectTypeIds'
import cardHeadingMap from './cardHeadingMap'

const getConferenceYear = (item) =>
  item.conferenceYear ??
  (item.items ?? item.sections?.flatMap((s) => s.items))
    ?.map((s) => s.conferenceYear)
    .filter((y) => !!y)
    .sort()
    .reverse()

export default function CardGroup({
  items,
  heading,
  noCrop,
  useChildLimiter = false,
  tags,
  initialCount = 4,
  forceIsPlaylistable,
  hideDivider,
  as = 'h3',
  maxColumnCount,
}) {
  const titledItems = items?.filter((item) => item.title !== null)
  const playlistMarkedList = titledItems
  const limit = useChildLimiter ? initialCount : 0

  return (
    <section data-testid="section-cards">
      <SectionHeading heading={heading} as={as} tags={tags} flavor={!hideDivider ? 'flavorless' : 'none'} />
      <CardGrid limit={limit} seeMoreMode="byLimit" maxColumnCount={maxColumnCount}>
        {Children.toArray(
          playlistMarkedList?.map((contentItem) => {
            const itemId = contentItem?.id ?? contentItem?.contentId
            const conferenceYear = getConferenceYear(contentItem)
            const percentageWatched = getPercentageWatched(contentItem)
            return (
              <Card
                as={cardHeadingMap[as]}
                contentId={contentItem?.id ?? contentItem?.contentId}
                conferenceYear={conferenceYear}
                data-testid={`card-${itemId}`}
                startTimestamp={contentItem?.date || null}
                image={getCustomImage(contentItem)}
                noCrop={noCrop}
                isLive={getIsLive(contentItem)}
                isInPlaylist={contentItem.isInPlaylist}
                isPlaylistable={
                  forceIsPlaylistable ||
                  getIsPlaylistable({
                    contentType: contentItem?.contentType,
                  })
                }
                heading={contentItem?.title ?? contentItem?.headingText}
                description={
                  getCreatorsNames(contentItem?.creators) ?? contentItem?.description ?? contentItem.detailText
                }
                contentLocale={contentItem.contentLocale}
                duration={getDuration(contentItem)}
                to={contentItem?.url ?? contentItem?.sessionUrl}
                percentageWatched={percentageWatched}
              />
            )
          })
        )}
      </CardGrid>
    </section>
  )
}
