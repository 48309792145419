import React from 'react'
import { buildChatRoute, CHAT_ROUTE_KEY, useChatOverlay } from '@fs/zion-chat'
import { i18n } from '@fs/zion-locale'
import ChatButton from '../ChatButton'
import { chatConfig, supportConfig, FLAGS } from '../../../config'
import getCommunityChatId from '../../../lib/helpers/getCommunityChatId'
import { useFlagsContext } from '../../../providers'

const AskUsAnythingButton = ({ onClick, ...props }) => {
  const { [FLAGS.CHAT_AUA_BUTTON]: auaButtonFlag } = useFlagsContext()
  const userLocale = i18n.language
  const loadCommunityChat = !auaButtonFlag.isOn || !supportConfig.chatLocales.includes(userLocale)

  const chatRoute = !loadCommunityChat
    ? buildChatRoute(CHAT_ROUTE_KEY.CREATE_SUPPORT_CHAT)
    : buildChatRoute(chatConfig.COMMUNITY_ROUTE, { cid: getCommunityChatId() })

  const chatOverlay = useChatOverlay(chatRoute)

  const onChatClick = (e) => {
    onClick?.()
    chatOverlay.handleClick(e)
  }

  return <ChatButton onClick={onChatClick} {...props} />
}

export default AskUsAnythingButton
