import React, { Children } from 'react'
import { Ghost, Separator } from '@fs/zion-ui'
import { css } from '@emotion/core'
import { boolAttr } from '@fs/zion-frontend-friends'
import { CardCarousel, cardHeadingMap, NewCard } from '../../../lib/card'
import {
  getCreatorsNames,
  getCustomImage,
  getIsPastBroadcast,
  isExternalUrl,
  getPercentageWatched,
  getDuration,
  isSessionDetail,
} from '../../../lib/helpers'
import { useIsMdWidth, getIsPlaylistable } from '../../../hooks'
import { SectionHeading } from '../../../lib/section-card'
import StructuredDataHorizontalCardGrid from './StructuredDataHorizontalCardGrid'

const cardCss = css`
  --cell-width: 252px;
  width: var(--cell-width);
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  height: 400px;
  // make sure the shadow of cards don't get cut off
  margin-block: 4px;

  &[is-md-width] {
    --cell-width: 200px;
    height: 350px;
  }
`

const generateCarouselStructuredData = (items) => {
  if (!items || items.length === 0) return null

  const validItems = items.filter((session) => {
    return isSessionDetail(session)
  })

  const carouselItems = validItems.map((session) => {
    const { item } = session
    const { title, url, sessionUrl } = item ?? session
    const actualUrl = url ?? sessionUrl
    const image = getCustomImage(item ?? session)

    return {
      '@type': 'ListItem',
      position: items.indexOf(session) + 1,
      name: title,
      url: actualUrl,
      image,
    }
  })

  return {
    '@context': 'https://schema.org',
    '@type': 'ItemList',
    itemListElement: carouselItems,
  }
}

const HorizontalCardGrid = ({ items, heading, as = 'h3', defaultDuration, actions }) => {
  const isMdWidth = useIsMdWidth()

  const carouselStructuredData = generateCarouselStructuredData(items)

  return (
    <div>
      <StructuredDataHorizontalCardGrid data={carouselStructuredData} />
      <SectionHeading heading={heading} flavor="none" as={as} actions={actions} />
      <Separator size="nano" />
      <CardCarousel>
        {Children.toArray(
          items?.map((session) => {
            const { date, isLive, item } = session
            const { title, creators, description, url, sessionUrl, conferenceYear } = item ?? session
            const videoDuration = getDuration(item ?? session)
            const percentageWatched = getPercentageWatched(item ?? session)
            const duration =
              defaultDuration && (!videoDuration || videoDuration === '0:00') ? defaultDuration : videoDuration
            const { contentLocale } = item?.video ?? session ?? {}
            const actualUrl = url ?? sessionUrl
            return (
              <div css={cardCss} is-md-width={boolAttr(isMdWidth)}>
                <Ghost keepVisible>
                  <NewCard
                    contentId={session?.contentId ?? item?.contentId ?? session?.id ?? item?.id}
                    isPlaylistable={getIsPlaylistable({
                      contentType: session?.contentType || item?.contentType,
                    })}
                    image={getCustomImage(item ?? session)}
                    maxImageHeight="200px"
                    heading={title}
                    description={getCreatorsNames(creators) ?? description}
                    isLive={isLive}
                    to={actualUrl}
                    contentLocale={contentLocale}
                    duration={duration}
                    external={isExternalUrl(url ?? sessionUrl)}
                    startTimestamp={!getIsPastBroadcast({ date, videoDuration: duration }) ? date : undefined}
                    conferenceYear={conferenceYear}
                    percentageWatched={percentageWatched}
                    as={cardHeadingMap[as]}
                    item={session}
                  />
                </Ghost>
              </div>
            )
          })
        )}
      </CardCarousel>
    </div>
  )
}

export default HorizontalCardGrid
