import React, { createContext, useReducer, useEffect, useContext, useState, useMemo } from 'react'
import { useHistory, useQueryParams } from '@fs/zion-router'
import { useUser } from '@fs/zion-user'
import useScheduleData from './hooks/useScheduleData'
import { useDetailAugmentation, useLazyRequest } from '../../hooks'
import { deleteTimeSlot, getUserSchedule, updateSchedule, updateTimeSlot } from '../../api'
import { consolidateMySchedule, isInMySchedule, mapToMyScheduleDate } from './helpers/scheduleHelpers'

const ScheduleContext = createContext()
export const useScheduleContext = () => useContext(ScheduleContext)

const actionTypes = {
  SET_DAY: 'SET_DAY',
  SET_MY_SCHEDULE: 'SET_MY_SCHEDULE',
  SET_SCHEDULE: 'SET_SCHEDULE',
  SET_TAB: 'SET_TAB',
  SET_TYPE: 'SET_TYPE',
  ADD_TO_MY_SCHEDULE: 'ADD_TO_MY_SCHEDULE',
  REMOVE_FROM_MY_SCHEDULE: 'REMOVE_FROM_MY_SCHEDULE',
}

const initialState = {
  day: '',
  tab: 'full_schedule',
  type: 'online',
  mySchedule: null,
  schedule: null,
}

const addToMyScheduleState = (mySchedule, date, time, contentId) => {
  const scheduleDay = mySchedule?.days?.[date]
  const timeslot = scheduleDay?.[time]
  if (timeslot) {
    // We already have a preferred session, add it to the alternates
    timeslot.alternates.push(contentId)
  } else {
    // We don't have the timeslot, add it to the date
    const timeSlot = {
      [time]: {
        preferred: contentId,
        alternates: [],
      },
    }
    mySchedule.days = { ...mySchedule.days, [date]: { ...scheduleDay, ...timeSlot } }
  }

  return mySchedule
}

const removeFromMyScheduleState = (mySchedule, date, time, contentId) => {
  let updatedScheduleDay = {}
  const scheduleDay = mySchedule?.days?.[date]
  const timeslot = scheduleDay?.[time]

  if (timeslot) {
    const { preferred, alternates } = timeslot
    // If the preferred ID is removed and there are no alternates, delete the timeslot.
    if (contentId === preferred && alternates.length === 0) {
      delete scheduleDay[time]
      updatedScheduleDay = { ...scheduleDay }
    } else {
      // Look for the contentId in the alternates array, extract it from the array into the preferred slot.
      if (alternates.includes(contentId)) {
        const index = alternates.indexOf(contentId)
        alternates.splice(index, 1)
      } // If the preferred is the item to remove, extract the first element from the alternates and replace it.
      else if (contentId === preferred && alternates.length > 0) {
        timeslot.preferred = alternates.shift()
      }
      updatedScheduleDay = { ...scheduleDay, [time]: timeslot }
    }
  }
  return updatedScheduleDay
}

const updateItemToMySchedule = (scheduleDay, contentId, calendarDate, inMySchedule = true) => {
  const updatedScheduleDay = scheduleDay.map((scheduleItem) => {
    if (scheduleItem.item.contentId === contentId && scheduleItem.date === calendarDate) {
      return {
        ...scheduleItem,
        item: { ...scheduleItem.item, inMySchedule },
      }
    }
    return scheduleItem
  })
  return updatedScheduleDay
}

function reducer(state, action) {
  switch (action.type) {
    case actionTypes.SET_TAB: {
      return {
        ...state,
        tab: action.value,
      }
    }
    case actionTypes.SET_DAY: {
      return {
        ...state,
        day: action.value,
      }
    }
    case actionTypes.SET_TYPE: {
      return {
        ...state,
        type: action.value,
      }
    }
    case actionTypes.SET_MY_SCHEDULE: {
      return {
        ...state,
        mySchedule: action.value,
      }
    }
    case actionTypes.ADD_TO_MY_SCHEDULE: {
      const { calendarDate, contentId, date, time } = action.value

      const localDate = mapToMyScheduleDate(new Date(calendarDate), true)

      const updatedMySchedule = addToMyScheduleState(state.mySchedule, date, time, contentId)
      const updatedScheduleDay = updateItemToMySchedule(state.schedule[localDate], contentId, calendarDate)

      return {
        ...state,
        schedule: { ...state.schedule, [localDate]: updatedScheduleDay },
        mySchedule: updatedMySchedule,
      }
    }
    case actionTypes.REMOVE_FROM_MY_SCHEDULE: {
      const { calendarDate, contentId, date, time } = action.value
      const localDate = mapToMyScheduleDate(new Date(calendarDate), true)

      const updatedMyScheduleDay = removeFromMyScheduleState(state.mySchedule, date, time, contentId)
      const updatedScheduleDay = updateItemToMySchedule(state.schedule[localDate], contentId, calendarDate, false)
      return {
        ...state,
        schedule: { ...state.schedule, [localDate]: updatedScheduleDay },
        mySchedule: {
          ...state.mySchedule,
          days: { ...state.mySchedule.days, [date]: updatedMyScheduleDay },
        },
      }
    }
    case actionTypes.SET_SCHEDULE: {
      const scheduleDayKeys = Object.keys(action.value)
      return {
        ...state,
        schedule: action.value,
        day: state.day || scheduleDayKeys[0],
      }
    }
    default: {
      return initialState
    }
  }
}

const groupByDay = (schedule) => {
  const scheduleGroupedIntoDays = {}
  schedule.forEach((item) => {
    const sessionDate = new Date(item.date)
    const dayId = mapToMyScheduleDate(sessionDate, true)
    scheduleGroupedIntoDays[dayId] = scheduleGroupedIntoDays[dayId] || []
    scheduleGroupedIntoDays[dayId].push(item)
  })
  return scheduleGroupedIntoDays
}

const addMyScheduleProp = (mySchedule, schedule) => {
  const scheduleDateKeys = Object.keys(schedule)
  const updatedSchedule = {}

  scheduleDateKeys.forEach((dateKey) => {
    schedule[dateKey].forEach((scheduleItem) => {
      const { date, item } = scheduleItem

      updatedSchedule[dateKey] = updatedSchedule[dateKey] || []
      updatedSchedule[dateKey].push({
        ...scheduleItem,
        item: { ...scheduleItem.item, inMySchedule: isInMySchedule(mySchedule, item.contentId, date) },
      })
    })
  })
  return updatedSchedule
}

export function ScheduleProvider({ children }) {
  const query = useQueryParams()
  const history = useHistory()
  const user = useUser()
  const [state, dispatch] = useReducer(reducer, { ...initialState, ...query.query })
  const [mySchedule, setMySchedule] = useState(null)
  const [rescheduledSessions, setRescheduledSessions] = useState(null)

  const { loading: scheduleLoading, data } = useScheduleData()
  const transformedData = useMemo(() => {
    return data.map(({ date, item }) => ({ ...item, date }))
  }, [data])

  // TODO: move this up inside the hooks; should we update this skip based on a criteria. Such as site experience
  const { data: augmentedData, loading } = useDetailAugmentation({
    loading: scheduleLoading,
    data: transformedData,
  })
  const formattedWatchHistoryData = useMemo(() => {
    return augmentedData.map((item) => ({ item, date: item.date }))
  }, [augmentedData])

  const [updateMySchedule] = useLazyRequest(updateSchedule)
  const [updateMyScheduleTimeslot] = useLazyRequest(updateTimeSlot)
  const [deleteMyScheduleTimeslot] = useLazyRequest(deleteTimeSlot)
  const [getMySchedule, { data: myScheduleData, loading: myScheduleLoading }] = useLazyRequest(getUserSchedule, {
    loading: true,
  })

  const changeTab = (value) => {
    dispatch({ type: actionTypes.SET_TAB, value })
    query.setQuery({ ...query.query, tab: value })
  }

  const changeDay = (value) => {
    dispatch({ type: actionTypes.SET_DAY, value })
    if (Object.keys(query.query).length === 0) {
      // Allows history navigation to don't skip previous page
      history.replace(`/schedule?day=${value}`)
    } else {
      query.setQuery({ ...query.query, day: value })
    }
  }

  // TODO: Maybe rename this to changeLocation, and the state/tab should be location
  const changeType = (value) => {
    dispatch({ type: actionTypes.SET_TYPE, value })
    query.setQuery({ ...query.query, type: value })
  }

  const addToMySchedule = (value) => {
    // The ScheduleCard expects a promise to be returned, so rather than change
    // functionality there, we just return one to make things not break
    if ((state.mySchedule.days[value.date]?.[value.time]?.alternates?.length ?? 0) >= 3) {
      return Promise.reject()
    }
    dispatch({ type: actionTypes.ADD_TO_MY_SCHEDULE, value })
    const body = state.mySchedule.days[value.date][value.time]

    return updateMyScheduleTimeslot({ date: value.date, time: value.time }, body)
  }

  const removeFromMySchedule = (value) => {
    dispatch({ type: actionTypes.REMOVE_FROM_MY_SCHEDULE, value })
    const body = state.mySchedule.days[value.date][value.time]
    if (body !== undefined) {
      updateMyScheduleTimeslot({ date: value.date, time: value.time }, body)
    } else {
      deleteMyScheduleTimeslot({ date: value.date, time: value.time })
    }
  }

  useEffect(() => {
    if (user?.signedIn && !user.userLoading && !myScheduleData && myScheduleLoading) {
      getMySchedule()
    }
  }, [myScheduleData, myScheduleLoading, getMySchedule, user])

  useEffect(() => {
    if (user?.signedIn && !myScheduleLoading && formattedWatchHistoryData.length) {
      // Consolidate the stored data from mySchedule with the calendar data.
      const { consolidatedMySchedule, rescheduledItems } = consolidateMySchedule(
        myScheduleData,
        formattedWatchHistoryData
      )

      // Call the service to save the consolidated mySchedule if there are session changes
      if (rescheduledItems.length) {
        updateMySchedule(consolidatedMySchedule)
      }
      dispatch({ type: actionTypes.SET_MY_SCHEDULE, value: consolidatedMySchedule || {} })
      setMySchedule(consolidatedMySchedule)
      setRescheduledSessions(rescheduledItems)
    }
  }, [formattedWatchHistoryData, myScheduleData, myScheduleLoading, updateMySchedule, user])

  useEffect(() => {
    if (formattedWatchHistoryData.length) {
      // Divide up the sessions by day
      const scheduleGroupedByDays = groupByDay(formattedWatchHistoryData)

      if (user?.signedIn && mySchedule) {
        const updatedSchedule = addMyScheduleProp(mySchedule, scheduleGroupedByDays)

        dispatch({ type: actionTypes.SET_SCHEDULE, value: updatedSchedule })
      } else {
        dispatch({ type: actionTypes.SET_SCHEDULE, value: scheduleGroupedByDays })
      }
    } else {
      dispatch({ type: actionTypes.SET_SCHEDULE, value: {} })
    }
  }, [formattedWatchHistoryData, mySchedule, user])

  return (
    <ScheduleContext.Provider
      // FIXME: This is a temporary fix. We need to remove this eslint-disable line and fix the issue.
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        ...state,
        addToMySchedule,
        removeFromMySchedule,
        changeTab,
        changeDay,
        changeType,
        loading,
        rescheduledSessions,
      }}
    >
      {children}
    </ScheduleContext.Provider>
  )
}
