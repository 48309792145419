import { brightcoveConfig } from '../../../config'

const isOnDemand = (livestream) => livestream?.livestreamId === 'onDemand'

export function getBrightcoveIds(videoProvider, isLive) {
  if (videoProvider?.providerType !== 'brightcove') return {}
  const { videoId, accountId, liveStream } = videoProvider
  if (liveStream && isLive && !isOnDemand(liveStream)) {
    return {
      videoId: liveStream.livestreamId,
      accountId: liveStream.accountId,
    }
  }

  if (!videoId || !accountId) return {} // throw an error?

  if (![brightcoveConfig.master.accountId, brightcoveConfig.liveStream.accountId].includes(accountId)) {
    return { videoId, accountId: brightcoveConfig.master.accountId } // This could lead to false-positives. considered safer in the end though.
  }
  return { videoId, accountId }
}

const isNumber = (value) => {
  return !Number.isNaN(parseFloat(value)) && Number.isFinite(value)
}

export function getVideoProvider(videoProvider, isLive) {
  if (!videoProvider) return 'none'
  const { videoId, providerType } = videoProvider

  switch (providerType) {
    case 'brightcove':
      if (!isLive && !isNumber(videoId)) {
        return 'processing'
      }
      return 'brightcove'
    case 'youtube':
      return 'youtube'
    case 'WEBINAR':
      return 'webinar'
    case 'countdown':
      return 'countdown'
    default:
      return undefined
  }
}
