import React from 'react'
import { SocialShare } from '@fs/zion-icon'
import { useOverlay } from '@fs/zion-ui'
import { isMobile } from 'react-device-detect'
import { trackLink } from '@fs/zion-analytics'
import { SocialDialog } from '@fs/zion-social'
import { useTranslation } from 'react-i18next'
import { removeHtml } from '@fs/zion-frontend-friends'
import ChewableButton from './ChewableButton'

export default function SocialShareButton({
  isSeries,
  url,
  thumbnail,
  title,
  description,
  linkPage,
  asIconButton,
  emphasis = 'low',
  size = 'lg',
}) {
  const [t] = useTranslation()
  const overlay = useOverlay()
  const socialPlatforms = isMobile
    ? ['facebook', 'messenger', 'twitter', 'whatsApp', 'line', 'email', 'copyLink']
    : ['facebook', 'messenger', 'twitter', 'whatsApp', 'kakaoTalk', 'line', 'email', 'copyLink']

  const subject = isSeries
    ? t('series.sharing-subject.label', 'Rootstech Series: {title}', {
        title,
      })
    : t('session.sharing-subject.label', 'Rootstech Session: {title}', {
        title,
      })

  const decodedDescription = new DOMParser().parseFromString(description, 'text/html').documentElement.textContent
  const sharable = {
    url,
    imageUrl: thumbnail,
    message: removeHtml(decodedDescription),
    subject,
  }
  const onSocialClick = (e) => {
    overlay.handleClick(e)
    trackLink({ name: `share-dialog: ${title}` })
  }

  return (
    <div data-testid="share">
      <ChewableButton
        Icon={SocialShare}
        onClick={onSocialClick}
        asIconButton={asIconButton}
        size={size}
        emphasis={emphasis}
      >
        {t('card.share', 'Share')}
      </ChewableButton>
      {/* {asIconButton ? (
        <TabletButton Icon={SocialShare} onClick={onSocialClick} />
      ) : (
        <PillButton emphasis={emphasis} size={size} Icon={SocialShare} onClick={onSocialClick}>
          {t('card.share', 'Share')}
        </PillButton>
      )} */}

      {(overlay.isOpen || overlay.transitioning) && (
        <SocialDialog
          {...overlay}
          sharable={sharable}
          platforms={socialPlatforms}
          product="rt"
          useIconButton
          emphasis="low"
          analytics={{ link_name: `share: ${title}`, link_page: linkPage, link_app: 'rootstech' }}
        />
      )}
    </div>
  )
}
