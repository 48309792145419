import React, { lazy, Suspense } from 'react'
import { Row, useOverlay, useTheme } from '@fs/zion-ui'
import { css } from '@emotion/core'
import { useTranslation } from 'react-i18next'
import { Link } from '@fs/zion-router'
import NavLinks from './NavLinks'
import LegalText from './LegalText'
import { rootstechLogoSvg, rootstechLogoLightSvg } from '../../images'
import { ResponsiveSeparator } from '../../lib'

const LangPicker = lazy(() => import('@fs/zion-lang-picker'))

const footerCss = css`
  padding: 0 24px 24px;
  position: relative;
`

export default function Footer() {
  const overlay = useOverlay()
  const [t] = useTranslation()
  const theme = useTheme()

  return (
    <footer css={footerCss}>
      <div>
        <Row alignX="center">
          <Link
            data-testid="rootstech-logo"
            to="/"
            aria-label={t('navigation.home.label', 'Home')}
            linkName="Nav: Logo"
          >
            <img
              src={theme === 'day' ? rootstechLogoLightSvg : rootstechLogoSvg}
              alt={t('rootstech.image', 'RootsTech by FamilySearch Logo')}
              width="123px"
              loading="lazy"
            />
          </Link>
        </Row>
        <ResponsiveSeparator />
        <NavLinks onLangClick={overlay.handleClick} />
        <LegalText />
      </div>
      <Suspense fallback={<span />}>
        {(overlay.isOpen || overlay.transitioning) && <LangPicker overlay={overlay} />}
      </Suspense>
    </footer>
  )
}
