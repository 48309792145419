import React, { forwardRef } from 'react'
import { Button, IconButton, colors, useContainerWidth } from '@fs/zion-ui'
import { useLocation } from '@fs/zion-router'
import { useTranslation } from 'react-i18next'
import { MenuSearch } from '@fs/zion-icon'
import { css } from '@emotion/core'
import { boolAttr } from '@fs/zion-frontend-friends'
import { useIsMdWidth } from '../../hooks'
import { flavors } from '../../config'
import { iconCss } from './NavButtonHack'

const buttonOverrideCss = css`
  height: 40px;
  align-items: center;
  transition: color 0.2s ease;
  position: relative;

  & a {
    color: ${colors.gray100} !important;
    font-weight: 600;

    :hover {
      background: none !important;
    }
  }

  ::after {
    content: '';
    height: 4px;
    width: 0;
    background: ${flavors.blueberry.solid};
    opacity: 0.5;
    position: absolute;
    bottom: -4px;
    border-radius: 4px;
    inset-inline-start: 0;
    inset-inline-end: 0;
    margin: 0 auto;
    transition: 0.1s;
  }
  :hover,
  :focus {
    color: ${colors.gray100};
    background: none !important;
    ::after {
      width: 50% !important;
    }
  }
  &[active] {
    border: none !important;
    color: ${colors.gray100};
    &::after {
      width: 100%;
      opacity: 1;
    }
  }
`

const SearchButton = forwardRef(({ ...props }, ref) => {
  const [t] = useTranslation()
  const isMdWidth = useIsMdWidth()
  const atWidth = useContainerWidth()
  const isFullscreen = atWidth({ xxl: true, default: false })
  const { pathname } = useLocation()
  const buttonProps = {
    ...props,
    Icon: MenuSearch,
    'aria-label': t('common-ui:search.action', 'Search'),
    linkName: 'Nav: Search',
    'data-testid': 'tab-search-button',
    to: '/search',
    ref,
  }

  return (
    <div css={isMdWidth || isFullscreen ? iconCss : buttonOverrideCss} active={boolAttr(pathname.includes(`search`))}>
      {isMdWidth || isFullscreen ? (
        <IconButton {...buttonProps} active={boolAttr(pathname.includes(`search`))} />
      ) : (
        <Button {...buttonProps}>{t('common-ui:search.action', 'Search')}</Button>
      )}
    </div>
  )
})

export default SearchButton
