import React from 'react'
import { Avatar, IconButton, useMenuOverlay, colors } from '@fs/zion-ui'
import { MediaPlay, SocialChat } from '@fs/zion-icon'
import { useLocation } from '@fs/zion-router'
import { css } from '@emotion/core'
import { useTranslation } from 'react-i18next'
import { boolAttr } from '@fs/zion-frontend-friends'
import ChatButton from './ChatButton'
import { AvatarMenuOverlay } from './AvatarMenuOverlay'
import { flavors } from '../../config'
import HelpButton from './HelpButton/HelpButton'
import LanguageButton from './LanguageButton/LanguageButton'
import usePortraitUrl from '../../hooks/usePortraitUrl'

const bodyCss = `
  color: ${colors.gray100};
  height: 40px;
  align-items: center;
  transition: color 0.2s ease;
  position: relative;

  &::after {
    content: '';
    height: 4px;
    width: 0;
    background: ${flavors.blueberry.solid};
    opacity: 0.5;
    position: absolute;
    bottom: -4px;
    border-radius: 4px;
    inset-inline-start: 0;
    inset-inline-end: 0;
    margin: 0 auto;
    transition: 0.1s;
  }
`

export const iconCss = css`
  & a {
    color: ${colors.gray100} !important;
    ${bodyCss}
  }
  a:hover,
  a:focus {
    background: none !important;
    &::after {
      width: 50%;
    }
  }
  & a[active] {
    &::after {
      width: 100%;
      opacity: 1;
    }
  }
`

const hoverCss = `
  color: ${colors.gray100}; 
  background: none !important;
  &::after {
    width: 50%;
  }
`
export const navMenuButtonCss = css`
  background: none;
  border: 0;
  & button {
    ${bodyCss}
  }

  button:hover,
  button:focus {
    ${hoverCss}
  }
  &[active] {
    border: none !important;
    color: ${colors.gray100};
    &::after {
      width: 100%;
      opacity: 1;
    }
  }
`

export function NavPlaylistButton() {
  const [t] = useTranslation()
  const { pathname } = useLocation()
  return (
    <div css={iconCss}>
      <IconButton
        Icon={MediaPlay}
        color="gray"
        size="xs"
        active={boolAttr(pathname.includes(`playlist`))}
        title={t('navigation.playlist.label', 'My Playlists')}
        data-testid="tab-playlist"
        linkName="Nav: My Playlist"
        to="/playlists/"
      />
    </div>
  )
}

export function NavChatButton() {
  const [t] = useTranslation()
  return (
    <div css={navMenuButtonCss}>
      <ChatButton Icon={SocialChat} emphasis="low" size="xs" title={t('chat.button', 'Chat')} />
    </div>
  )
}

export function NavHelpButton() {
  return (
    <div css={navMenuButtonCss}>
      <HelpButton size="xs" />
    </div>
  )
}

export function NavLanguageButton() {
  return (
    <div css={navMenuButtonCss}>
      <LanguageButton size="xs" />
    </div>
  )
}

export const NavAvatarButton = ({ user, dense }) => {
  const overlay = useMenuOverlay()
  const portraitUrl = usePortraitUrl()
  const [t] = useTranslation()

  return (
    user && (
      <>
        <div css={navMenuButtonCss} active={boolAttr(overlay.isOpen)}>
          <button
            style={{ background: 'none', border: 0 }}
            type="button"
            data-testid="nav-user-menu-button"
            onClick={overlay.handleClick}
            aria-label={t('navigation.avatar.label', 'User Menu')}
            data-link-name="Nav: User Menu"
          >
            <Avatar size="xs" src={portraitUrl} sex={user.gender?.toLowerCase()} />
          </button>
        </div>
        {(overlay.isOpen || overlay.transitioning) && <AvatarMenuOverlay dense={dense} {...overlay} />}
      </>
    )
  )
}
