import React from 'react'
import { WhiteTheme } from '@fs/zion-ui'
import { Badge } from './Badge'
import { useTimeTravel } from '../providers'
import { hour } from '../config'

export default function Classroom({ classroom, date, sessionLocation }) {
  const { getNow } = useTimeTravel()
  const isInPerson = sessionLocation?.includes('In Person')
  const currentDate = new Date(getNow())
  const hourPastStartTime = date + 1 * hour
  return classroom && currentDate <= hourPastStartTime && isInPerson ? (
    <Badge
      data-testid={`item-${classroom}`}
      flavor="flavorless"
      keyline="left"
      label={<WhiteTheme>{classroom}</WhiteTheme>}
    />
  ) : null
}
