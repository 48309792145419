import React from 'react'
import { ListItem } from '@fs/zion-ui'
// import { format } from '@fs/zion-locale/date-fns'
import { useFeatureFlag } from '@fs/zion-flags'
import { getI18nDate } from '../../helpers'

const Overline = ({ date, endTime }) => {
  const scheduledPlayerQAFlag = useFeatureFlag('rootstech_qa_scheduled_player')

  if (!date) return null

  if (scheduledPlayerQAFlag.isOn) {
    return `${getI18nDate(date, { time: true, timezone: true })} - ${getI18nDate(endTime, { time: true, timezone: true })}`
  }
  // TODO: when they fix the overline proptypes to allow <time> we can add this back in. https://icseng.atlassian.net/browse/FRONTIER-1455
  // <time dateTime={format(date, 'yyyy-MM-dd h:MM')}>{getI18nDate(date, { time: true, timezone: true })}</time>
  return getI18nDate(date, { time: true, timezone: true })
}

export default function PlayerListItem({ session, onClick, playingIdentifier, selected }) {
  const scheduledPlayerQAFlag = useFeatureFlag('rootstech_qa_scheduled_player')
  const { date, item, endTime } = session
  const { title } = item

  return (
    <ListItem
      onClick={onClick}
      // This is necessary to prevent a proptype error: Invalid prop `overline` of type `object` supplied to `TextBlock`, expected `string`. If the flag is on, we don't care about that error
      overline={
        scheduledPlayerQAFlag.isOn ? (
          <Overline date={date} endTime={endTime} />
        ) : (
          getI18nDate(date, { time: true, timezone: true })
        )
      }
      primaryText={
        <>
          {playingIdentifier}
          {title}
        </>
      }
      selected={selected}
    />
  )
}
