import { useEffect, useState } from 'react'
import getPermission, { usePermission } from '@fs/zion-permissions'
import { getAnonSession, getSessionId } from '@fs/zion-session'
import zionDebug from '@fs/zion-debug'
import { useStatusOverlay } from '@fs/zion-ui'
import { useUser } from '@fs/zion-user'
import notifySentry from '../lib/helpers/sentryNotifier'

const debug = zionDebug('rootstech:permissions:userdata')

const handlePermissionError = (signedIn, error) => {
  if (signedIn) {
    notifySentry(error)
  }
}

export default function useAnonCanAddPersonalData() {
  const permission = 'CanAddPersonalDataPermission'
  const context = 'FamilyTree'
  const showStatusOverlay = useStatusOverlay()
  const sessionId = getSessionId()
  const [hasPermission, setHasPermission] = useState(false)
  const { signedIn } = useUser()
  const [isAuthorized, status, error] = usePermission(permission, context)
  handlePermissionError(signedIn, error, showStatusOverlay)
  debug('sessionId', sessionId)
  debug('hasPermission', hasPermission)
  debug('usePermission', isAuthorized, status, error)

  useEffect(() => {
    if (error || !sessionId) {
      // only use AnonSession if usePermission fails or if there is no sessionId
      getAnonSession()
        .then(() => getPermission(permission, context))
        .then(({ authorized }) => {
          debug('authorized', authorized)
          authorized && setHasPermission(authorized)
        })
    }
  }, [context, permission, error, sessionId])
  return isAuthorized || hasPermission
}
