import React, { createContext, useContext, useMemo, useState, useCallback } from 'react'
import { getNowTimestamp } from '../../../lib/helpers'

const TimeContext = createContext()
export const useTimeTravel = () => useContext(TimeContext)

export const TimeTravelProvider = ({ children }) => {
  const [temporalNow, setTemporalNow] = useState(getNowTimestamp())

  // FIXME: does not work as expected when in a timeout/interval
  const getNow = useCallback(() => {
    // positive num means the now state is in the future
    // negative num means the now state is in the past
    const difference = temporalNow - getNowTimestamp()
    return getNowTimestamp() + difference
  }, [temporalNow])

  const value = useMemo(() => ({ getNow, setNow: setTemporalNow }), [getNow])

  return <TimeContext.Provider value={value}>{children}</TimeContext.Provider>
}
