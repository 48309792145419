import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { trackLink } from '@fs/zion-analytics'
import { ContentAdd, ContentCheck } from '@fs/zion-icon'
import { useUser } from '@fs/zion-user'
import { useOverlay } from '@fs/zion-ui'
import { useLoggedOutOverlay } from '../../providers/LoggedOutOverlayProvider'
import { useTimeTravel } from '../../providers'
import ChewableButton from './ChewableButton'
import PlaylistManagementOverlay from '../../components/playlists/components/PlaylistManagementOverlay'
import { isContentAvailable } from '../helpers'

export default function PlaylistsButton({ item, sessionId, asIconButton, size, ...props }) {
  const [t] = useTranslation()
  const user = useUser()
  const managementOverlay = useOverlay()
  const { overlay: loggedOutOverlay } = useLoggedOutOverlay()
  const { getNow } = useTimeTravel()
  const [isInSomePlaylist, setIsInSomePlaylist] = useState(Boolean(item?.playlists))

  const onClick = (e) => {
    const loggedIn = user?.signedIn ?? false
    if (!loggedIn) {
      trackLink({ name: 'Playlists: logged out', action: 'click_action' })
      loggedOutOverlay?.handleClick?.(e)
    } else {
      trackLink({ name: 'Playlists: logged in', action: 'click_action' })
      managementOverlay.handleClick(e)
    }
  }

  const label = t('playlists.label', 'Playlists')

  // todo: figure out if it's possible to show just the icon in color
  const useColoredButton = false // improvedUxEnabled && isInSomePlaylist

  const handlePlaylistUpdated = (playlistIds) => {
    if (playlistIds.length > 0) {
      setIsInSomePlaylist(true)
    } else {
      setIsInSomePlaylist(false)
    }
  }
  const isAvailable = isContentAvailable({ item, now: getNow() })
  return (
    <>
      {isAvailable && (
        <ChewableButton
          asIconButton={asIconButton}
          size={size}
          onClick={onClick}
          Icon={isInSomePlaylist ? ContentCheck : ContentAdd}
          data-testid="playlists-dialog"
          emphasis="low"
          color={useColoredButton ? 'blue' : null}
          {...props}
        >
          {label}
        </ChewableButton>
      )}
      {(managementOverlay.isOpen || managementOverlay.transitioning) && (
        <PlaylistManagementOverlay
          target={managementOverlay}
          sessionId={sessionId}
          onPlaylistUpdated={handlePlaylistUpdated}
        />
      )}
    </>
  )
}
