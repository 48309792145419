import React from 'react'
import { css } from '@emotion/core'
import { colors } from '@fs/zion-ui'
import { flavors } from '../../config'

const buttonCss = css`
  position: fixed;
  bottom: 140px;
  padding-inline-start: 4px;

  color: ${colors.gray100};
  border: none;
  border-start-end-radius: 5px;
  border-end-end-radius: 5px;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  z-index: 2;
  height: 40px;
  width: 32px;

  &:hover {
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.3);
  }
`

export default function FixedButton({ ariaLabel, onClick, Icon, flavor }) {
  return (
    <button
      aria-label={ariaLabel}
      type="button"
      onClick={onClick}
      css={buttonCss}
      style={{ background: flavors[flavor].gradient }}
    >
      <Icon role="none" />
    </button>
  )
}
