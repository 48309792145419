import React from 'react'
import { H2, LayoutBand, Row, Separator } from '@fs/zion-ui'
import { useTranslation } from 'react-i18next'
import { ReasonsToLogIn } from './LoggedOutOverlay'
import { PillButton } from '../buttons'
import { authConfig } from '../../config'

export default function SignInWithReasons({ returnUrl }) {
  const [t] = useTranslation()

  return (
    <LayoutBand>
      <Row alignX="center">
        <ReasonsToLogIn as={H2} />
      </Row>
      <Separator size="md" />
      <Row alignX="center" wrap>
        <PillButton
          emphasis="medium"
          external
          linkName="Sign In"
          target="_self"
          to={`${authConfig.loginPath}?returnUrl=${encodeURIComponent(returnUrl)}`}
        >
          {t('common-ui:sign-in.action', 'Sign In')}
        </PillButton>
        <PillButton
          emphasis="medium"
          target="_self"
          linkName="Create an Account"
          to={`${authConfig.registerPath}?returnUrl=${encodeURIComponent(returnUrl)}`}
          external
        >
          {t('logged-out.create-an-account', 'Create an Account')}
        </PillButton>
      </Row>
    </LayoutBand>
  )
}
