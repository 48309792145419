import React from 'react'
import { Separator, H2, MarginBox } from '@fs/zion-ui'
import { useTranslation } from 'react-i18next'
import { css } from '@emotion/core'
import { stageSeafoamJpg } from '../../images'

const backgroundCss = css`
  background-image: url(${stageSeafoamJpg});
  aspect-ratio: 16 / 9;
  display: grid;
  place-content: center;
  background-size: cover;
`

const VideoProcessing = () => {
  const [t] = useTranslation()
  const margin = 'xl'
  return (
    <div css={backgroundCss}>
      <MarginBox marginX={margin} marginY={margin}>
        <Separator />
        <H2 supplementary centered>
          {t('video-player.processing-on-demand', 'Your video is being processed for on-demand viewing')}
        </H2>
      </MarginBox>
    </div>
  )
}

export default VideoProcessing
