import { getCalendarDetail } from '../../../api'
import { useErrorHandlerOverlay } from '../../../hooks'
import { brightspotConfig } from '../../../config'
import { CALENDAR_MAIN_STAGE } from '../helpers/scheduleHelpers'
import { useLoggedQuery } from '../../../providers'

const { promoImage, thumbnailImage, profileImage } = brightspotConfig.imageSizes
export default function useCalendarData({ calendarType = CALENDAR_MAIN_STAGE }) {
  const variables = {
    id: `/calendar/${calendarType}`,
    ...profileImage,
    ...promoImage,
    ...thumbnailImage,
  }
  const { loading, data, error } = useLoggedQuery('useCalendarData', getCalendarDetail(), { variables })
  useErrorHandlerOverlay(error)

  return { loading, data: data?.CalendarDetail?.stages?.[0]?.calendarItems, error }
}
