import { getOneOffPage } from '../api'
import useErrorHandlerOverlay from './useErrorHandlerOverlay'
import { brightspotConfig } from '../config'
import useDetailAugmentation from './useDetailAugmentation'
import { useLoggedQuery } from '../providers/ApolloProviders'

const { imageSizes } = brightspotConfig
const { leadThumbnailImage, leadPromoImage, leadProfileImage, leadImage, profileImage, promoImage, thumbnailImage } =
  imageSizes

export default function useOneOffPageData({ path }) {
  const variables = {
    path,
    ...leadPromoImage,
    ...leadProfileImage,
    ...leadThumbnailImage,
    ...leadImage,
    ...profileImage,
    ...promoImage,
    ...thumbnailImage,
  }
  const { loading, data, error } = useLoggedQuery('useOneOffPageData', getOneOffPage(), { variables })
  useErrorHandlerOverlay(error)

  const { data: augmentedData, loading: augmentedLoading } = useDetailAugmentation({
    loading,
    data: data?.OneOffPageDetail,
  })

  return { loading: augmentedLoading, data: augmentedData, error }
}
