import React, { createContext, useContext, useMemo, useState } from 'react'

const FeedbackContext = createContext()
export const useFeedbackContext = () => useContext(FeedbackContext)

export const FeedbackProvider = ({ children }) => {
  const [shouldHideGlobalFeedback, setShouldHideGlobalFeedback] = useState(false)

  const value = useMemo(() => ({ shouldHideGlobalFeedback, setShouldHideGlobalFeedback }), [shouldHideGlobalFeedback])

  return <FeedbackContext.Provider value={value}>{children}</FeedbackContext.Provider>
}
