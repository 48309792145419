import { useCallback, useEffect, useState } from 'react'
import { getProfilePortrait, getPortraitUrls } from '@fs/zion-portrait-service'
import { useUser } from '@fs/zion-user'

export default function usePortraitUrl() {
  const { cisId, personId } = useUser()
  const [portraitUrl, setPortraitUrl] = useState('')

  const getData = useCallback(async () => {
    const portrait = await getProfilePortrait(cisId)
    const treePortrait = await getPortraitUrls(personId)

    if (portrait?.avatarUrl) {
      setPortraitUrl(portrait.avatarUrl)
    } else if (treePortrait?.thumbSquare?.href) {
      setPortraitUrl(treePortrait.thumbSquare.href)
    }
  }, [cisId, personId])

  useEffect(() => {
    if (cisId) getData()
  }, [getData, cisId])

  return portraitUrl
}
