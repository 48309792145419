import { useState, useEffect, useCallback, useRef } from 'react'
import { getNowTimestamp } from '../helpers'

export default function useCountdownTimer(targetTime, interval) {
  const [duration, setDuration] = useState(Infinity)
  const timerRef = useRef()

  const updateDuration = useCallback(() => {
    // NOTE: time travel does work when inside a an interval/timeout
    const remainingTime = targetTime - getNowTimestamp()
    if (remainingTime <= 0) {
      setDuration(0)
      return
    }

    setDuration(remainingTime)
    timerRef.current = setTimeout(updateDuration, interval)
  }, [interval, targetTime])

  useEffect(() => {
    updateDuration()

    return () => {
      clearTimeout(timerRef.current)
    }
  }, [updateDuration])

  return duration
}
