import { useEffect, useCallback } from 'react'
import { useQuery } from '@apollo/client'
import zionDebug from '@fs/zion-debug'
import { splunk } from '@fs/zion-splunk-logger'
import { useFeatureFlag } from '@fs/zion-flags'

const debug = zionDebug('rootstech')

/**
 * Execute the Apollo query and log the result to console and splunk.
 * @param source {string} - The source of the query (method name).
 * @param query {DocumentNode} - The Apollo query to execute.
 * @param options {object} - Additional options for the query.
 * @returns {{data, error, loading}} - The result of the query.
 */
export default function useLoggedQuery(source, query, options) {
  const { data, error, loading } = useQuery(query, options)
  const logGraphQLDetails = useFeatureFlag('rootstech_log_graphql_details')

  const log = useCallback(
    (status) => {
      splunk.log({
        id: 'RootsTech_GraphQL',
        source,
        status,
        data: status === 'success' ? '<skipped>' : data?.substring(0, 200) || '<empty>',
        error,
      })
    },
    [source, data, error]
  )

  useEffect(() => {
    if (loading) {
      return
    }
    if ((debug.enabled || logGraphQLDetails.isOn) && !loading && !error) {
      log(`success`)
    }
    if (!loading && error) {
      log(`error`)
    }
  }, [loading, data, error, logGraphQLDetails.isOn, log])

  return { data, error, loading }
}
