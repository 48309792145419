import { buildChatRoute, CHAT_ROUTE_KEY } from '@fs/zion-chat'
import React from 'react'
import { i18n } from '@fs/zion-locale'
import ChatButton from '../../components/header/ChatButton'
import { chatConfig, supportConfig, FLAGS } from '../../config'
import getCommunityChatId from '../helpers/getCommunityChatId'
import { useFlagsContext } from '../../providers'

export default function AUAChatButton({ children, asComponent, ...props }) {
  const { [FLAGS.CHAT_AUA_BUTTON]: auaButtonFlag } = useFlagsContext()
  const userLocale = i18n.language
  const loadCommunityChat = !auaButtonFlag.isOn || !supportConfig.chatLocales.includes(userLocale)

  const chatRoute = !loadCommunityChat
    ? buildChatRoute(CHAT_ROUTE_KEY.CREATE_SUPPORT_CHAT)
    : buildChatRoute(chatConfig.COMMUNITY_ROUTE, { cid: getCommunityChatId() })

  return (
    <ChatButton asComponent={asComponent} chatRoute={chatRoute} {...props}>
      {children}
    </ChatButton>
  )
}
