import React, { Children } from 'react'
import { Separator, useContainerWidth } from '@fs/zion-ui'
import PropTypes from 'prop-types'
import { Card, CardGrid } from '../../../lib/card'
import { SectionCard } from '../../../lib/section-card'
import { getCustomImage, getDuration, getIsLive, getPercentageWatched } from '../../../lib/helpers'
import { PillButton } from '../../../lib/buttons'
import { getIsPlaylistable } from '../../../hooks'
import { validFlavors } from '../../../config'
import SpeakerCard from '../../../lib/card/SpeakerCardGrid/SpeakerCard'

function RainbowCarouselSection({
  overline,
  title,
  items,
  description,
  ctaUrl,
  ctaLabel,
  flavor = 'apple',
  image,
  position = 0,
}) {
  const atWidth = useContainerWidth()

  const isSpeakerSection = items?.every((item) => item?.url?.includes('/speakers/'))

  const limit = atWidth({ default: 4, lg: isSpeakerSection ? 6 : 3, xl: isSpeakerSection ? 6 : 4 })

  return title ? (
    <SectionCard
      overline={overline}
      heading={title}
      description={description}
      flavor={flavor}
      image={image}
      actions={
        ctaUrl && (
          <PillButton to={ctaUrl} data-testid="cta-button" linkName={`Section: ${title}: ${ctaLabel}`}>
            {ctaLabel}
          </PillButton>
        )
      }
      data-testid={`cms-section-${position}`}
    >
      <Separator size="xxs" />
      <CardGrid limit={limit} seeMoreMode="byRow" maxColumnCount={isSpeakerSection ? 6 : undefined}>
        {Children.toArray(
          items
            ?.filter((contentItem) => contentItem.contentId || contentItem.image?.id || contentItem.url)
            ?.map((contentItem, itemIndex) => {
              const contentId = contentItem?.contentId ?? contentItem?.contentUUID
              const to = contentItem?.url || contentItem?.sessionUrl
              const percentageWatched = getPercentageWatched(contentItem)
              if (isSpeakerSection) {
                return <SpeakerCard {...contentItem} />
              }
              return (
                <Card
                  image={getCustomImage(contentItem)}
                  noCrop={to?.includes('/expohall/')}
                  heading={contentItem?.title ?? ''}
                  description={contentItem?.description ?? ''}
                  conferenceYear={contentItem?.conferenceYear || undefined}
                  contentId={contentId}
                  data-testid={`featured-sessions-row-card${itemIndex}`}
                  startTimestamp={contentItem?.date}
                  isInPlaylist={contentItem.isInPlaylist}
                  isPlaylistable={getIsPlaylistable(contentItem)}
                  isLive={getIsLive(contentItem)}
                  key={contentId}
                  contentLocale={contentItem.contentLocale}
                  duration={getDuration(contentItem)}
                  to={to}
                  percentageWatched={percentageWatched}
                  item={contentItem}
                />
              )
            })
        )}
      </CardGrid>
    </SectionCard>
  ) : null
}

export default RainbowCarouselSection

RainbowCarouselSection.propTypes = {
  title: PropTypes.string.isRequired,
  image: PropTypes.string,
  description: PropTypes.string,
  ctaUrl: PropTypes.string,
  ctaLabel: PropTypes.string,
  flavor: PropTypes.oneOf(validFlavors),
  items: PropTypes.arrayOf(
    PropTypes.shape({
      UUID: PropTypes.string,
      creators: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string,
          profilePhoto: PropTypes.shape({
            custom: PropTypes.shape({
              image: PropTypes.shape({
                src: PropTypes.string,
              }),
            }),
          }),
        })
      ),
      description: PropTypes.string,
      image: PropTypes.shape({
        custom: PropTypes.shape({
          image: PropTypes.shape({
            src: PropTypes.string,
          }),
        }),
      }),
      objectTypeId: PropTypes.string,
      title: PropTypes.string,
      url: PropTypes.string,
      __typename: PropTypes.string,
    })
  ),
}
