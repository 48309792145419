import React, { Children } from 'react'
import { useTranslation } from 'react-i18next'
import { ContentCheck } from '@fs/zion-icon'
import { useHistory, useQueryParams } from '@fs/zion-router'
import { DialogOverlay, DialogOverlayActions, H3, Separator, List, ListItem } from '@fs/zion-ui'
import { useFeatureFlag } from '@fs/zion-flags'
import PillButton from '../buttons/PillButton'
import { useLoggedOutOverlay } from '../../providers/LoggedOutOverlayProvider'
import { useIsMdWidth } from '../../hooks'
import { authConfig, flavors } from '../../config'

function ReasonItem({ children }) {
  return (
    <ListItem
      dense
      startElement={<ListItem.Icon Icon={ContentCheck} color={flavors.apple.solid} />}
      primaryText={children}
    />
  )
}

export function ReasonsToLogIn({ as = null }) {
  const chatFlag = useFeatureFlag('rootstech_chat')
  const rarFlag = useFeatureFlag('rootstech_rar')
  const [t] = useTranslation()
  const { listItems } = useLoggedOutOverlay()
  const Tag = as || H3
  return (
    <>
      <Tag size="H5">{t('logged-out.subheader', 'Watch videos online plus:')}</Tag>
      <Separator size="sm" />
      <List>
        {Children.toArray(listItems.map((item) => <ReasonItem>{item}</ReasonItem>))}
        {chatFlag.isOn && (
          <ReasonItem>{t('logged-out.reason2', 'Chat with industry experts and other attendees')}</ReasonItem>
        )}
        {rarFlag.isOn && <ReasonItem>{t('logged-out.reason3', 'See your relatives attending RootsTech')}</ReasonItem>}
        <ReasonItem>{t('logged-out.reason5', 'Access to all RootsTech content')}</ReasonItem>
      </List>
    </>
  )
}

export default function LoggedOutOverlay({ guestRedirect = '/', ...overlay }) {
  const [t] = useTranslation()
  const history = useHistory()
  const pageQuery = useQueryParams()
  const { query } = useLoggedOutOverlay()
  const isMdWidth = useIsMdWidth()

  const onDynamicRedirect = (to) => {
    if (to === '/back') {
      history.goBack()
    } else if (to) {
      history.go(to)
    }
    overlay.close()
  }

  let redirect
  if (query) {
    const redirectUrl = new URL(window.location.toString().split('?')[0])
    if (Object.keys(query).length > 0) {
      const redirectQuery = { ...pageQuery.query, ...(query ?? {}) }
      Object.entries(redirectQuery).forEach(([k, v]) => redirectUrl.searchParams.set(k, v))
    }
    redirect = redirectUrl.toString()
  } else {
    redirect = window.location.toString()
  }

  return (
    <DialogOverlay
      {...overlay}
      title={t('logged-out.header', 'Sign In to Your Account')}
      footer={
        <DialogOverlayActions wrap>
          <PillButton
            data-testid="continue-as-guest"
            emphasis="low"
            onClick={() => onDynamicRedirect(guestRedirect)}
            color="blue"
            linkName="No Thanks"
            size={isMdWidth ? 'sm' : undefined}
          >
            {t('logged-out.no-thanks', 'No Thanks')}
          </PillButton>
          <PillButton
            to={`${authConfig.registerPath}?returnUrl=${encodeURIComponent(redirect)}`}
            external
            target="_self"
            emphasis="medium"
            data-testid="register"
            linkName="Create an Account"
            size={isMdWidth ? 'sm' : undefined}
          >
            {t('logged-out.create-an-account', 'Create an Account')}
          </PillButton>
          <PillButton
            data-testid="sign-in-LoggedOutOverlay"
            external
            target="_self"
            to={`${authConfig.loginPath}?returnUrl=${encodeURIComponent(redirect)}`}
            emphasis="high"
            linkName="Sign In"
            size={isMdWidth ? 'sm' : undefined}
          >
            {t('common-ui:sign-in.action', 'Sign In')}
          </PillButton>
        </DialogOverlayActions>
      }
    >
      <ReasonsToLogIn />
    </DialogOverlay>
  )
}
