import React from 'react'
import { FlowGrid, useContainerWidth, Image, H2, Separator, Subheading, Ellipsis } from '@fs/zion-ui'
import ErrorBoundary from '@fs/zion-error-boundary'
import { useTranslation } from 'react-i18next'
import { css } from '@emotion/core'
import { PillButton } from '../buttons'
import SafeRichText from '../SafeRichText'
import { VideoProvider } from '../video'
import { getCustomImage } from '../helpers'
import ErrorFallback from '../ErrorFallback'

const bulletCss = css`
  & li {
    margin-inline-start: 20px;
  }
`
const eagerImageCss = css`
  display: block;
  object-fit: cover;
`

const Visual = ({ eager, ...props }) => {
  return eager ? <img alt="" {...props} css={eagerImageCss} /> : <Image {...props} />
}

export default function MediaPromo({
  promo,
  children,
  noAction,
  videoAtEnd,
  isGif,
  buttonEmphasis = 'high',
  endGutter,
  shouldEllipsis = true,
  noTooltip = false,
  eager = false,
}) {
  const [t] = useTranslation()
  const atWidth = useContainerWidth()
  const { title, description, video, ctaText, url } = promo

  const isMobile = atWidth({ default: true, md: false })
  const lineClamp = atWidth({ default: 4, sm: 5, md: 6, lg: 7, xl: 8 })

  const videoComponent = (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      {!isGif && <VideoProvider videoProvider={video?.videoProvider} thumbnail={getCustomImage(promo)} />}
      {isGif && (
        <Visual
          eager={eager}
          src={getCustomImage(promo)}
          alt=""
          style={{ aspectRatio: isMobile ? '3 / 2' : '16 / 9' }}
        />
      )}
    </ErrorBoundary>
  )
  const copyText = (
    <div style={{ marginInlineStart: isMobile ? 12 : 0, marginInlineEnd: !endGutter || isMobile ? 0 : '15%' }}>
      <H2 size="H3">
        {title}
        <Separator size="xxs" />
        <Subheading>
          <span css={bulletCss}>
            {shouldEllipsis ? (
              <Ellipsis lineClamp={lineClamp} noTooltip={noTooltip}>
                <SafeRichText size="sm" as="span" dirtyInput={description} />
              </Ellipsis>
            ) : (
              <SafeRichText size="sm" as="span" dirtyInput={description} />
            )}
          </span>
        </Subheading>
      </H2>
      <Separator />
      {!noAction && url && (
        <>
          <Separator size="sm" />
          {children || (
            <PillButton to={url} linkName={ctaText || 'Media Promo: Learn More'} emphasis={buttonEmphasis}>
              {ctaText || t('common-ui:learn-more.action', 'Learn More')}
            </PillButton>
          )}
          <Separator size="sm" />
        </>
      )}
    </div>
  )

  return (
    <FlowGrid columnCount={isMobile ? 1 : 2}>
      {!videoAtEnd && videoComponent}
      {!videoAtEnd && copyText}
      {videoAtEnd && copyText}
      {videoAtEnd && videoComponent}
    </FlowGrid>
  )
}
