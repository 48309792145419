import { gql } from '@apollo/client'
import { brightspotConfig } from '../../config'
import { ThumbnailImage } from './fragments/ImageDetail'
import { ConferencePromo, MinimumCreatorDetail } from './fragments/ConferencePromo'
import Pagination from './fragments/Pagination'
import gqlSanitize from './stringUtils'
import { loadChunks } from '../helpers/pagination'
import ImageAttributes from './fragments/ImageAttributes'

const { defaults } = brightspotConfig.query
const { promoImage, profileImage, thumbnailImage } = brightspotConfig.imageSizes

export const queries = {
  searchSessions: () =>
    gql(`
    ${ThumbnailImage}
    ${ConferencePromo}
    ${Pagination}
    ${MinimumCreatorDetail}
    query SessionSearch(
      $ids: [String],
      $pageRequest: PaginationInput = ${gqlSanitize(defaults.pageRequest)},
      $promoImage_crop: Boolean = false,
      $promoImage_height: Int = ${defaults.imageSize},
      $promoImage_width: Int = ${defaults.imageSize},
      $thumbnailImage_crop: Boolean = false,
      $thumbnailImage_height: Int = ${defaults.imageSize},
      $thumbnailImage_width: Int = ${defaults.imageSize},
      $profileImage_crop: Boolean = false,
      $profileImage_height: Int = ${defaults.imageSize},
      $profileImage_width: Int = ${defaults.imageSize}) {
      SessionSearch(
        ids: $ids, 
        pageRequest: $pageRequest) {
        paginationData {
          ...Pagination
        } 
        results {
          creators {
            ...MinimumCreatorDetail
          }
          date
          description
          exhibitors {
            name
            url
          }
          id
          sessionUrl
          title
          url
          promo {
            ...ConferencePromo 
          }
          video {
            videoProvider {
              ... on YoutubeVideoProvider {
                videoId
              }
              ... on RootsTechBrightcoveVideoProvider {
                videoId
              }
            }
            thumbnail {
              ...ThumbnailImage
            }
          }
          __typename
        }
      }
    }
  `),

  searchSeries: () =>
    gql(`
    ${ThumbnailImage}
    ${ConferencePromo}
    ${Pagination}
    ${MinimumCreatorDetail}
    query SeriesSearch(
      $contentIds: [String],
      $pageRequest: PaginationInput = ${gqlSanitize(defaults.pageRequest)},
      $promoImage_crop: Boolean = false,
      $promoImage_height: Int = ${defaults.imageSize},
      $promoImage_width: Int = ${defaults.imageSize},
      $thumbnailImage_crop: Boolean = false,
      $thumbnailImage_height: Int = ${defaults.imageSize},
      $thumbnailImage_width: Int = ${defaults.imageSize},
      $profileImage_crop: Boolean = false,
      $profileImage_height: Int = ${defaults.imageSize},
      $profileImage_width: Int = ${defaults.imageSize}) {
      SeriesSearch(
        ids: $contentIds, 
        pageRequest: $pageRequest) {
        paginationData {
          ...Pagination
        } 
        results {
          creators {
            ...MinimumCreatorDetail
          }
          description
          id
          promo {
            ...ConferencePromo
          }
          sections {
            items {
              conferenceYear
            }
          }
          title
          url
          videoThumbnailImage {
            ...ThumbnailImage
          }
          __typename
        }
      }
    }
  `),

  lightweightSessionSearch: () =>
    gql(`
    ${ImageAttributes}
    query findSessions(
      $ids: [String],
      $pageSize: Int = ${defaults.pageRequest.limit},
    ) {
      SessionSearch(ids: $ids, pageRequest: { limit: $pageSize }) {
        results {
          conferenceYear
          creators {
            id
            name
            url
          }
          date
          description
          exhibitors {
            id
            name
            url
          }
          id
          sessionDifficulty
          title
          url
          video {
            thumbnail {
              alt
              attributes {
                ...ImageAttributes
              }
              contentLocale
              custom(crop: false, height: ${defaults.imageSize}, width: ${defaults.imageSize}) {
                image {
                  ...ImageAttributes
                }
              }
              id
              source
              url
            }
            videoDuration
          }
          __typename
        }
      }
    }
  `),
}

export async function findSessions(client, ids) {
  return getPageWithRetry(
    (variables) => client.query({ query: queries.lightweightSessionSearch(), variables }),
    { ids, pageSize: ids.length },
    'SessionSearch'
  ).then((response) => response.results)
}

export async function getAllSessions(client, ids) {
  const queryFunction = (variables) => client.query({ query: queries.searchSessions(), variables })
  return loadChunks(
    ids,
    (v) => getPageWithRetry(queryFunction, v, 'SessionSearch'),
    (chunkIds) => ({
      ids: chunkIds,
      pageRequest: { ...defaults.pageRequest },
      ...promoImage,
      ...thumbnailImage,
      ...profileImage,
    })
  )
}

export async function getAllSeries(client, ids) {
  const queryFunction = (variables) => client.query({ query: queries.searchSeries(), variables })
  return loadChunks(
    ids,
    (v) => getPageWithRetry(queryFunction, v, 'SeriesSearch'),
    (chunkIds) => ({
      contentIds: chunkIds,
      pageRequest: { ...defaults.pageRequest },
      ...promoImage,
      ...thumbnailImage,
      ...profileImage,
    })
  )
}

async function getPageWithRetry(queryFunction, variables, dataKey) {
  let failures = 0
  let error = null
  do {
    try {
      // eslint-disable-next-line no-await-in-loop
      const result = await queryFunction(variables)
      return result.data[dataKey]
    } catch (err) {
      error = err
    }
  } while (++failures < 3)

  throw error
}
