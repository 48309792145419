import PropTypes from 'prop-types'
import React from 'react'
import { Card, Row, Bleed, MarginBox } from '@fs/zion-ui'
import { css } from '@emotion/core'
import { boolAttr } from '@fs/zion-frontend-friends'
import { flavors, validFlavors } from '../config'

const rainbowCardCss = css`
  height: 100%;
  justify-content: center;
  &[is-active],
  &:focus,
  &:focus-within,
  &:active,
  &:hover {
    background: var(--gradient);
  }
`

export default function RainbowHoverCard({
  children,
  flavor = flavors.default,
  size = 'nano',
  proportional = false,
  active = false,
  ...props
}) {
  return (
    <Card {...props}>
      <Bleed css={rainbowCardCss} all style={{ '--gradient': flavors[flavor].gradient }} is-active={boolAttr(active)}>
        <MarginBox marginY={size} marginX={proportional ? size : 'none'}>
          <Row alignX="center" alignY="middle">
            {children}
          </Row>
        </MarginBox>
      </Bleed>
    </Card>
  )
}

RainbowHoverCard.propTypes = {
  children: PropTypes.node,
  flavor: PropTypes.oneOf(validFlavors),
  size: PropTypes.oneOf(['xxl', 'xl', 'lg', 'md', 'sm', 'xs', 'xxs', 'nano', 'none']),

  /** whether or not to make the margin x the same size as margin y */
  proportional: PropTypes.bool,
}
