import React, { Children } from 'react'
import { List, ListItem, H3, Separator, Divider, FlowGrid, Paragraph, useContainerWidth } from '@fs/zion-ui'
import { useTranslation } from 'react-i18next'
import {
  moreToExploreAlreadyRegisteredSvg,
  moreToExploreBrowseSvg,
  moreToExploreExpoHallSvg,
  moreToExploreFamilyTreeSvg,
  moreToExploreFamousRelativesSvg,
  moreToExploreMemoriesSvg,
  moreToExploreMiniEventsSvg,
  moreToExplorePlaylistsSvg,
  moreToExploreRegionalPageSvg,
  moreToExploreRelativesAtRootstechSvg,
  moreToExploreScheduleSvg,
  moreToExploreSearchSvg,
  moreToExploreSpeakersSvg,
} from '../../../images'
import { FLAGS } from '../../../config'
import { useFlagsContext } from '../../../providers'
import ResponsiveLayoutBand from '../../../lib/ResponsiveLayoutBand'
import useShowExpoHall from '../../../hooks/useShowExpoHall'

const MoreToExploreCard = ({ subheading, items }) => {
  const atWidth = useContainerWidth()
  const dense = atWidth({ default: true, md: false })
  return (
    <div>
      <Paragraph size="sm">{subheading}</Paragraph>
      <Divider />
      <List>{Children.toArray(items.map((item) => <ListItem {...item} emphasized dense={dense} />))}</List>
      {dense && <Separator size="xs" />}
    </div>
  )
}

const MoreToExplore = () => {
  const atWidth = useContainerWidth()
  const { t } = useTranslation()
  const { [FLAGS.SCHEDULE]: scheduleFlag, [FLAGS.SITE_EXPERIENCE]: siteExperienceFlag } = useFlagsContext()
  const { treatment } = siteExperienceFlag
  const showExpoHall = useShowExpoHall()

  const moreToExploreHeading = t('home.more-to-explore.heading', 'More to Explore')
  const moreToExploreSubheading =
    treatment === 'afterConference'
      ? t('home.more-to-explore.subheading-afterEvent', 'Conference Event')
      : t('home.more-to-explore.subheading-event', '2025 Event')

  const moreToExploreData = [
    {
      subheading: moreToExploreSubheading,
      items: [
        {
          startElement: <ListItem.Image src={moreToExploreScheduleSvg} alt="" />,
          primaryText: scheduleFlag.isOn
            ? t('navigation.class-schedule.label', 'Class Schedule')
            : t('home.more-to-explore.item.primary-text-class-preview', 'Class Preview'),
          to: '/schedule',
          linkName: 'class_preview',
          'data-testid': 'class-preview',
        },
        // {
        //   startElement: <ListItem.Image src={moreToExploreAlreadyRegisteredSvg} alt="" />,
        //   primaryText: t('home.more-to-explore.item.primary-already-registered', 'Already Registered'),
        //   to: '/expohall/prepare',
        //   linkName: 'already_registered',
        //   'data-testid': 'already-registered',
        // },
        {
          startElement: <ListItem.Image src={moreToExploreAlreadyRegisteredSvg} alt="" />,
          primaryText: t('expo-hall.promotions.heading', 'Promotions for RootsTech'),
          to: '/expohall/promotions',
          linkName: 'Promotions for RootsTech',
          'data-testid': 'promotions-for-rootstech',
        },
        {
          startElement: <ListItem.Image src={moreToExploreSpeakersSvg} alt="" />,
          primaryText: t('home.more-to-explore.item.primary-text-see-our-speakers', 'See Our Speakers'),
          to: '/speakers',
          linkName: 'see_our_speakers',
          'data-testid': 'see-our-speakers',
        },
      ],
    },
    {
      subheading: t('home.more-to-explore.subheading-content-library', 'Content Library'),
      items: [
        {
          startElement: <ListItem.Image src={moreToExploreSearchSvg} alt="" />,
          primaryText: t('home.more-to-explore.item.primary-text-search', 'Search'),
          to: '/search',
          linkName: 'search',
          'data-testid': 'search',
        },
        {
          startElement: <ListItem.Image src={moreToExplorePlaylistsSvg} alt="" />,
          primaryText: t('home.more-to-explore.item.primary-text-my-playlist', 'My Playlist'),
          to: '/playlists',
          linkName: 'my_playlist',
          'data-testid': 'my-playlist',
        },
        {
          startElement: <ListItem.Image src={moreToExploreBrowseSvg} alt="" />,
          primaryText: t('home.more-to-explore.item.primary-text-browse-sessions', 'Browse Sessions'),
          to: '/library',
          linkName: 'browse_sessions',
          'data-testid': 'browse-sessions',
        },
        {
          startElement: <ListItem.Image src={moreToExploreRegionalPageSvg} alt="" />,
          primaryText: t('home.more-to-explore.item.primary-text-regional-pages', 'Regional Pages'),
          to: '/regions',
          linkName: 'regional_pages',
          'data-testid': 'regional-pages',
        },
      ],
    },
    {
      subheading: t('home.more-to-explore.subheading-connect-with-family-search', 'Connect with FamilySearch'),
      items: [
        {
          startElement: <ListItem.Image src={moreToExploreFamilyTreeSvg} alt="" />,
          primaryText: t('home.more-to-explore.item.primary-text-family-tree', 'Build Your Family Tree'),
          to: 'https://www.familysearch.org/tree/guided/connect-to-tree',
          linkName: 'build_your_family_tree',
          'data-testid': 'build-your-family-tree',
          target: '_blank',
        },
        {
          startElement: <ListItem.Image src={moreToExploreRelativesAtRootstechSvg} alt="" />,
          primaryText: t('home.more-to-explore.item.primary-text-together', 'Together App'),
          to: 'https://www.familysearch.org/discovery/together',
          linkName: 'together_app',
          'data-testid': 'together-app',
          target: '_blank',
        },
        {
          startElement: <ListItem.Image src={moreToExploreFamousRelativesSvg} alt="" />,
          primaryText: t('home.more-to-explore.item.primary-text-famous-relatives', 'Famous Relatives'),
          to: 'https://www.familysearch.org/connect/famous',
          linkName: 'famous_relatives',
          'data-testid': 'famous-relatives',
          target: '_blank',
        },
        {
          startElement: <ListItem.Image src={moreToExploreMemoriesSvg} alt="" />,
          primaryText: t('home.more-to-explore.item.primary-text-surname-discovery', 'Surname Discovery'),
          to: 'https://www.familysearch.org/surname',
          linkName: 'surname_discovery',
          'data-testid': 'surname-discovery',
          target: '_blank',
        },
      ],
    },
  ]

  if (showExpoHall) {
    moreToExploreData[0].items.push({
      startElement: <ListItem.Image src={moreToExploreExpoHallSvg} alt="" />,
      primaryText: t('home.more-to-explore.item.primary-text-expo-hall', 'Expo Hall'),
      to: '/expohall',
      linkName: 'expo_hall',
      'data-testid': 'expo-hall',
    })
  }
  if (treatment === 'afterConference') {
    moreToExploreData[0].items.push({
      startElement: <ListItem.Image src={moreToExploreMiniEventsSvg} alt="" />,
      primaryText: t('home.more-to-explore.item.latter-day-saint-sessions', 'Latter-day Saint Sessions'),
      to: '/topics/latter-day-saint-sessions',
      linkName: 'latter-day_saint_sessions',
      'data-testid': 'latter-day-saint-sessions',
    })
  }

  return (
    <ResponsiveLayoutBand size="xl">
      <H3 centered>{moreToExploreHeading}</H3>
      <Separator />
      <FlowGrid columnCount={atWidth({ default: 1, md: 3 })} gutters={atWidth({ default: 'xs', xs: 'xs', sm: 'sm' })}>
        {Children.toArray(
          moreToExploreData.map((data) => <MoreToExploreCard subheading={data.subheading} items={data.items} />)
        )}
      </FlowGrid>
    </ResponsiveLayoutBand>
  )
}

export default MoreToExplore
