import React from 'react'
import { css } from '@emotion/core'
import { useTranslation } from 'react-i18next'
import { MarginBox, Separator, useContainerWidth, Row, H2 } from '@fs/zion-ui'
import { videoPlayerFallbackJpg } from '../../images'
import PillButton from '../buttons/PillButton'

const fallbackCss = css`
  background:
    linear-gradient(0deg, #000000a6 25%, #0000008c 40%, transparent 90%),
    var(--image) center/cover;
  aspect-ratio: 16 / 9;
`
const layoutCss = css`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 100%;
`

const VideoPlayerFallback = ({ children }) => {
  const [t] = useTranslation()
  const atWidth = useContainerWidth()
  const marginSize = atWidth({ default: 'sm', md: 'lg' })

  return (
    <div style={{ '--image': `url(${videoPlayerFallbackJpg})` }} css={fallbackCss}>
      <MarginBox marginY={marginSize} marginX={marginSize} css={layoutCss}>
        <H2 centered size={atWidth({ default: 'H5', lg: 'H4', xl: 'H3' })}>
          {children}
        </H2>
        <Separator />
        <Row alignX="center">
          <PillButton to="/library" size={atWidth({ default: 'sm', md: 'lg' })}>
            {t('home.video-fallback.button', 'Browse Now')}
          </PillButton>
        </Row>
      </MarginBox>
    </div>
  )
}

export default VideoPlayerFallback
