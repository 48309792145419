import React, { createContext, useContext } from 'react'
import { getLocales } from '../api'
import useErrorHandlerOverlay from '../hooks/useErrorHandlerOverlay'
import { languageConfig } from '../config'
import { useLoggedQuery } from './ApolloProviders'

export const LocalesContext = createContext()
export const useLocalesContext = () => useContext(LocalesContext)

const useLocalesData = () => {
  const { loading, data, error } = useLoggedQuery('useLocalesData', getLocales())
  useErrorHandlerOverlay(error)

  let locales = data
  if (!loading && data) {
    const unsortedLocales = [...data.ConferenceDataViewModel.locales]
    const sortedLocales = unsortedLocales
      .sort((first, second) => `${first.label}`.localeCompare(second.label))
      .map(({ displayLanguage, ...locale }) => {
        const chineseLocales = languageConfig.chineseLocales
        const label = chineseLocales.includes(locale.value) ? locale.label : displayLanguage
        return { ...locale, label }
      })
    locales = sortedLocales
  }

  return { loading, locales, error }
}

export function LocalesProvider({ children }) {
  const { loading, locales, error } = useLocalesData()

  const contextValue = React.useMemo(() => ({ loading, locales, error }), [loading, locales, error])

  return <LocalesContext.Provider value={contextValue}>{children}</LocalesContext.Provider>
}
