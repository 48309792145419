import {
  FlowGrid,
  Row,
  Separator,
  useContainerWidth,
  colors,
  Paragraph,
  Overline,
  Subheading,
  H2,
  H4,
} from '@fs/zion-ui'
import { useTranslation, Trans } from 'react-i18next'
import React from 'react'
import { parseMsToISO, dateTimeFormat } from '../helpers'
import { PillButton } from '../buttons'
import { circlesFireJpg, circlesSeafoamJpg } from '../../images'
import { conferenceConfig, cventConfig, registrationConfig } from '../../config'
import { useTimeTravel } from '../../providers'
import { BorderCard } from '../card'

const PHASES = {
  EARLY_BIRD: 'EARLY_BIRD',
  BLACK_FRIDAY: 'BLACK_FRIDAY',
  LAST_CHANCE: 'LAST_CHANCE',
  STANDARD: 'STANDARD',
}

const {
  earlyBirdStartTimestamp,
  earlyBirdEndTimestamp,
  blackFridayStartTimestamp,
  blackFridayEndTimestamp,
  lastChanceStartTimestamp,
  lastChanceEndTimestamp,
} = conferenceConfig
const { attendeePricing } = registrationConfig

const getPricing = (phase, pass) => {
  const { promo, standard } = attendeePricing

  if (pass === 'oneDay') {
    switch (phase) {
      case PHASES.EARLY_BIRD:
      case PHASES.BLACK_FRIDAY:
      case PHASES.LAST_CHANCE:
        return promo.oneDay
      default:
        return standard.oneDay
    }
  }

  switch (phase) {
    case PHASES.EARLY_BIRD:
    case PHASES.BLACK_FRIDAY:
    case PHASES.LAST_CHANCE:
      return promo.threeDay
    default:
      return standard.threeDay
  }
}

const getDateOverline = ({ t, phase, pass }) => {
  switch (phase) {
    case PHASES.EARLY_BIRD:
      if (pass === 'oneDay') {
        return `${t('in-person.pricing.early-bird.one-day', 'Early Bird Price – Save $10')}*`
      }
      return `${t('in-person.pricing.early-bird.three-day', 'Early Bird Price – Save $30')}*`
    case PHASES.BLACK_FRIDAY:
      if (pass === 'oneDay') {
        return `${t('in-person.pricing.black-friday.one-day', 'Black Friday Price – Save $10')}*`
      }
      return `${t('in-person.pricing.black-friday.three-day', 'Black Friday Price – Save $30')}*`
    case PHASES.LAST_CHANCE:
      if (pass === 'oneDay') {
        return `${t('in-person.pricing.last-chance.one-day', 'Last Chance Price – Save $10')}*`
      }
      return `${t('in-person.pricing.last-chance.three-day', 'Last Chance Price – Save $30')}*`
    // standard
    default:
      // empty space to help with layout
      return '\u00A0'
  }
}

const getFootnote = (t, phase) => {
  const dateTimeFormatOptions = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  }
  const endDate = {
    [PHASES.EARLY_BIRD]: earlyBirdEndTimestamp,
    [PHASES.BLACK_FRIDAY]: blackFridayEndTimestamp,
    [PHASES.LAST_CHANCE]: lastChanceEndTimestamp,
  }[phase]

  if (endDate) {
    return t(
      'in-person.promo-pricing.footnote',
      '*Standard Pricing of $129 for a 3-day pass and $79 for a 1-day pass starts {endDate}',
      { endDate: dateTimeFormat(endDate, dateTimeFormatOptions) }
    )
  }

  return ''
}

const isTimestampBetween = (target, start, end) => {
  const targetDate = new Date(target)
  const startDate = new Date(start)
  const endDate = new Date(end)

  return targetDate >= startDate && targetDate <= endDate
}
const getPricingPhase = (now) => {
  if (isTimestampBetween(now, earlyBirdStartTimestamp, earlyBirdEndTimestamp)) return PHASES.EARLY_BIRD
  if (isTimestampBetween(now, blackFridayStartTimestamp, blackFridayEndTimestamp)) return PHASES.BLACK_FRIDAY
  if (isTimestampBetween(now, lastChanceStartTimestamp, lastChanceEndTimestamp)) return PHASES.LAST_CHANCE
  return PHASES.STANDARD
}

const PricingCard = ({ title, overline, heading, subHeading, to, linkName, src, flavor, buttonText }) => {
  return (
    <BorderCard backgroundImage={src} topHeading={title} flavor={flavor} hideDivider as="h3">
      <Separator />
      <H4 size="H2" centered>
        <Overline>{overline}</Overline>
        {heading}
        <Separator size="xxs" />
        <Subheading>{subHeading}</Subheading>
      </H4>
      <Separator size="sm" />
      <Row alignX="center">
        <PillButton to={to} linkName={linkName} target="_blank" noPrependLocaleCode>
          {buttonText}
        </PillButton>
      </Row>
      <Separator />
    </BorderCard>
  )
}

export default function PricingCards() {
  const [t] = useTranslation()
  const atWidth = useContainerWidth()
  const { getNow } = useTimeTravel()
  const nowString = parseMsToISO(getNow())

  const pricingPhase = getPricingPhase(nowString)

  return (
    <>
      <H2 size="H3" centered>
        {t('in-person.pricing.2024', '2025 In-Person Pricing')}
        <Separator size="xxs" />
        <Subheading>
          {t(
            'in-person.pricing.subheading',
            'Come to Salt Lake City to experience 250+ exclusive sessions and develop new friendships and reunite with old friends.'
          )}
        </Subheading>
      </H2>
      <Separator />
      <FlowGrid columnCount={atWidth({ default: 1, md: 2 })}>
        <PricingCard
          src={circlesFireJpg}
          title={t('in-person.pricing.3-day-pass', '3-Day Pass')}
          overline={getDateOverline({ t, phase: pricingPhase, pass: 'threeDay' })}
          heading={
            <Trans
              i18nKey="in-person.pricing.price"
              // eslint-disable-next-line no-template-curly-in-string
              defaults="${price} <S>US</S>"
              values={{ price: getPricing(pricingPhase, 'threeDay') }}
              components={{ S: <span style={{ color: colors.gray50 }} /> }}
            />
          }
          subHeading={
            pricingPhase !== PHASES.STANDARD && (
              <Trans
                i18nKey="in-person.pricing.3-day-standard-price"
                defaults="<S>$129</S> Standard Price"
                components={{ S: <s /> }}
              />
            )
          }
          to={cventConfig.registrationUrl}
          linkName="Registration: 3-day-pass"
          flavor="mango"
          buttonText={t('in-person.pricing.get-3-day-pass', 'Get 3-Day Pass')}
        />
        <PricingCard
          src={circlesSeafoamJpg}
          title={t('in-person.pricing.1-day-pass', '1-Day Pass')}
          overline={getDateOverline({ t, phase: pricingPhase, pass: 'oneDay' })}
          heading={
            <Trans
              i18nKey="in-person.pricing.price"
              // eslint-disable-next-line no-template-curly-in-string
              defaults="${price} <S>US</S>"
              values={{ price: getPricing(pricingPhase, 'oneDay') }}
              components={{ S: <span style={{ color: colors.gray50 }} /> }}
            />
          }
          subHeading={
            pricingPhase !== PHASES.STANDARD && (
              <Trans
                i18nKey="in-person.pricing.1-day-standard-price"
                defaults="<S>$79</S> Standard Price"
                components={{ S: <s /> }}
              />
            )
          }
          to={cventConfig.registrationUrl}
          linkName="Registration: 1-day-pass"
          flavor="fire"
          buttonText={t('in-person.pricing.get-1-day-pass', 'Get 1-Day Pass')}
        />
      </FlowGrid>
      <Separator size="xxs" />
      <Row alignX="center">
        <Paragraph size="sm" centered>
          {getFootnote(t, pricingPhase)}
        </Paragraph>
      </Row>
    </>
  )
}
