const LABS_PREF = 'fslabs.labs'

const FLAGS_WITH_PREFS = {
  EXPOHALL: 'rootstech_expohall',
  PREVIEW: 'rootstech_preview',
  SITE_EXPERIENCE: 'rootstech_site_experience',
  CHAT_AUA_BUTTON: 'messaging_chat_aua_button',
  CHAT_ASKUSANYTHING: 'messaging_chat_askUsAnything',
  CHAT_HELPER: 'messaging_chat_helper',
}
const FLAGS_WITHOUT_PREFS = {
  BANNER_MAINTENANCE: 'rootstech_bannerMaintenance',
  CALL_FOR_PRESENTATIONS: 'rootstech_callForPresentations',
  CHAT: 'rootstech_chat',
  CHAT_MENU: 'rootstech_chat_menu',
  DASHBOARD: 'rootstech_dashboard',
  DAY_THEME: 'rootstech_day_theme',
  EMBEDDED_CHAT_FALLBACK: 'messaging_chat_show_embedded_fallback_for_all',
  ENGLISH_ONLY_BANNER: 'rootstech_english_only_banner',
  EMBEDDED_CHAT: 'rootstech_embedded_chat',
  FORCE_LIVE: 'rootstech_force_live',
  HISTORY_DELETE: 'rootstech_history_delete',
  IN_PERSON: 'rootstech_in_person',
  IN_PERSON_BANNER: 'rootstech_in_person_banner',
  JOIN_EMBEDDED_CHAT: 'rootstech_join_embedded_chat',
  LATAM_EL_CAMINO: 'rootstech_latam_el_camino',
  LOG_GRAPHQL_DETAILS: 'rootstech_log_graphql_details',
  MARKETING: 'rootstech_marketing',
  NO_SESSION_COUNTDOWN: 'rootstech_no_session_countdown',
  PERSIST_QUERIES: 'rootstech_persist_queries',
  PLAYLISTS_DRAG_N_DROP: 'rootstech_playlists_drag_n_drop',
  QA_OPTIONS: 'rootstech_qa_options',
  QA_SCHEDULED_PLAYER: 'rootstech_qa_scheduled_player',
  RAR: 'rootstech_rar',
  REGION_BANNER: 'rootstech_region_banner',
  SCHEDULE: 'rootstech_schedule',
  SEARCH_FILTER_TAGS: 'rootstech_search_filter_tags',
  SESSION_FEEDBACK: 'rootstech_session_feedback',
  SPONSOR: 'rootstech_sponsor',
  STORE: 'rootstech_store',
  SUBSCRIPTION: 'rootstech_subscription',
  VENDOR_CHAT: 'rootstech_vendor_chat',
  VENDOR_LINKING: 'rootstech_vendor_linking',
  VOLUNTEER: 'rootstech_volunteer',
  EXTERNAL_NAVIGATION_OVERLAY: 'rootstech_external_nav_overlay',
}

export const FLAGS = {
  ...FLAGS_WITH_PREFS,
  ...FLAGS_WITHOUT_PREFS,
}

export const flagsConfig = {
  flagsWithPrefs: [
    { flag: FLAGS.PREVIEW, pref: LABS_PREF },
    { flag: FLAGS.SITE_EXPERIENCE, pref: LABS_PREF },
    { flag: FLAGS.EXPOHALL, pref: LABS_PREF },
    { flag: FLAGS.CHAT_AUA_BUTTON, pref: LABS_PREF },
    { flag: FLAGS.CHAT_ASKUSANYTHING, pref: LABS_PREF },
    { flag: FLAGS.CHAT_HELPER, pref: LABS_PREF },
  ],
  flagsWithoutPrefs: Object.values(FLAGS_WITHOUT_PREFS),
}
