import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useUser } from '@fs/zion-user'
import Cache from '@fs/zion-cache'
import useWatchHistoryApi from '../api/rootstech/watch-history'
import { parseMsToISO } from '../lib/helpers'

const WatchHistoryContext = createContext()
export const useWatchHistoryContext = () => useContext(WatchHistoryContext)

export function WatchHistoryProvider({ children }) {
  const user = useUser()
  const api = useWatchHistoryApi()

  const [enabled, setEnabled] = useState(false)

  const [cache, setCache] = useState(null)
  useEffect(() => {
    async function doLoad() {
      if (user.signedIn) {
        const summary = await api.getWatchHistory()

        if (cache) {
          return
        }
        setCache(
          new Cache({
            storeName: 'rootstech_watch_history',
            type: 'memory',
            lifetime: 'hour',
          })
        )
        setEnabled(summary.enabled)
      } else {
        setCache(null)
      }
    }
    doLoad()
  }, [api, cache, user.signedIn])

  const getSummary = useCallback(async () => {
    if (!user.signedIn) {
      return {
        enabled: true,
        size: 0,
        recentEntries: [],
      }
    }

    return api.getWatchHistory()
  }, [api, user.signedIn])

  const getHistoryEntries = useCallback(
    async ({ ids }) => {
      if (!user.signedIn) {
        return []
      }

      // todo: auto-split into batches of 50

      const lookups = await Promise.all(
        ids.map((id) => cache.getItem(id).then((val) => val ?? { sessionId: id, notFound: true }))
      )
      const [existingEntries, remainingIds] = lookups.reduce(
        ([e, i], result) => {
          if (result.mostRecentPosition !== undefined || result.completed) {
            e.push(result)
          } else if (result.notFound) {
            i.push(result.sessionId)
          }

          return [e, i]
        },
        [[], []]
      )

      const newEntries = await api.getHistoryEntries(remainingIds)
      await Promise.all(newEntries.map((entry) => cache.setItem(entry.sessionId, entry)))

      const finalResult = [...existingEntries, ...newEntries]

      const watchedIds = new Set(newEntries.map((e) => e.sessionId))

      // put an empty result in to avoid looking up unwatched videos later
      await Promise.all(
        remainingIds
          .filter((id) => !watchedIds.has(id))
          .map((id) => cache.setItem(id, { sessionId: id, mostRecentPosition: null, completed: false }))
      )

      return finalResult
    },
    [api, cache, user.signedIn]
  )

  const updateEntry = useCallback(
    async ({ sessionId, position, completed }) => {
      if (Number.isInteger(position)) {
        position = parseMsToISO(position)
      }

      await api.addWatchHistoryEntry({ id: sessionId, mostRecentPosition: position, completed: !!completed })
      if (!cache) {
        return
      }
      await cache.setItem(sessionId, {
        sessionId,
        mostRecentPosition: position,
        completed: !!completed,
      })
    },
    [api, cache]
  )

  const updateEnabledSetting = useCallback(
    async (setting) => {
      if (setting === enabled) {
        // nothing to do
        return
      }
      await api.setWatchHistoryEnabled(setting)
      setEnabled(setting)
    },
    [api, enabled]
  )

  const value = useMemo(
    () => ({ enabled, updateEnabledSetting, getSummary, getHistoryEntries, updateEntry }),
    [enabled, updateEnabledSetting, getSummary, getHistoryEntries, updateEntry]
  )

  return <WatchHistoryContext.Provider value={value}>{children}</WatchHistoryContext.Provider>
}
