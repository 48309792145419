import React from 'react'
import { css } from '@emotion/core'
import PropTypes from 'prop-types'
import { Ellipsis, colors } from '@fs/zion-ui'
import { useTranslation } from 'react-i18next'
import { i18n } from '@fs/zion-locale'
import { validFlavors, flavors, FLAGS } from '../config'
import { getLanguageName } from './helpers/languageHelpers'
import { useFlagsContext } from '../providers'
import { getEnv } from './helpers'

const badgeCss = css`
  background: ${colors.gray02};
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  padding: 2px 7px;
  &[key-line='left'] {
    padding-top: 2px;
    padding-inline-end: 5px;
    padding-bottom: 2px;
    padding-inline-start: 0px;
  }
  border-radius: 3.6px;
  text-transform: uppercase;
  height: 20px;
  font-weight: 700;
  font-size: 12px;

  background-color: var(--background-color);
  color: var(--color);
`

export const Badge = ({ label, flavor = flavors.default, keyline, ...props }) => {
  return (
    <div
      css={badgeCss}
      style={{ '--background-color': flavors[flavor].solid, '--color': flavors[flavor].textColor || colors.gray00 }}
      {...props}
      key-line={keyline}
    >
      <Ellipsis>{label}</Ellipsis>
    </div>
  )
}

Badge.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  flavor: PropTypes.oneOf(validFlavors),
}

export const SeriesBadge = () => {
  const [t] = useTranslation()
  return <Badge flavor="seafoam" label={t('card.tag.series', 'Series')} data-testid="badge-series" />
}

export const LanguageBadge = ({ contentLocale }) => {
  const userLocale = i18n.language
  const contentIsInUsersLanguage = contentLocale?.split('-')[0] === userLocale
  if (!contentLocale || contentIsInUsersLanguage || contentLocale === 'und') return null

  const translatedName = getLanguageName(contentLocale.split('-')[0])

  return <Badge flavor="charcoal" label={translatedName} data-testid={`badge-language-${contentLocale}`} />
}

export const LiveBadge = () => {
  const [t] = useTranslation()
  return <Badge flavor="lava" label={t('card.tag.live', 'Live')} data-testid="badge-live" />
}

export const DraftBadge = () => {
  const [t] = useTranslation()
  return <Badge flavor="sunrise" label={t('card.tag.draft', 'Draft')} data-testid="badge-draft" />
}

export const SubmittedBadge = () => {
  const [t] = useTranslation()
  return <Badge flavor="apple" label={t('card.tag.submitted', 'Submitted')} data-testid="badge-submitted" />
}

export const EditableBadge = () => {
  const [t] = useTranslation()
  return <Badge flavor="sunrise" label={t('card.tag.editable', 'In Progress')} data-testid="badge-editable" />
}

export const AcceptedBadge = () => {
  const [t] = useTranslation()
  return <Badge flavor="accepted" label={t('card.tag.accepted', 'Accepted')} data-testid="badge-accepted" />
}

export const PublishedBadge = () => {
  const [t] = useTranslation()
  return <Badge flavor="pending" label={t('card.tag.published', 'Published')} data-testid="badge-published" />
}

export const PendingBadge = () => {
  const [t] = useTranslation()
  return <Badge flavor="pending" label={t('card.tag.pending', 'Pending')} data-testid="badge-pending" />
}

export const ApprovedBadge = () => {
  const [t] = useTranslation()
  return <Badge flavor="approved" label={t('card.tag.approved', 'Approved')} data-testid="badge-approved" />
}

export const DeclinedBadge = () => {
  const [t] = useTranslation()
  return <Badge flavor="declined" label={t('card.tag.declined', 'Declined')} data-testid="badge-declined" />
}

export const RevokedBadge = () => {
  const [t] = useTranslation()
  return <Badge flavor="declined" label={t('card.tag.revoked', 'Revoked')} data-testid="badge-revoked" />
}

export const OnlineBadge = () => {
  const [t] = useTranslation()
  return (
    <Badge
      flavor="online"
      label={t('upload-content.session-location.online.label', 'Online')}
      data-testid="badge-online"
    />
  )
}

export const InPersonBadge = () => {
  const [t] = useTranslation()
  return (
    <Badge
      flavor="gold"
      label={t('upload-content.session-location.in-person.label', 'In person')}
      data-testid="badge-inperson"
    />
  )
}

export const InPersonOnlyBadge = () => {
  const [t] = useTranslation()
  return (
    <Badge
      flavor="gold"
      label={t('upload-content.session-location.in-person-only.label', 'This class is not available online')}
      data-testid="badge-inpersononly"
    />
  )
}

export const SessionDifficultyBadge = ({ sessionDifficulty }) => {
  const [t] = useTranslation()

  const difficulties = {
    Beginner: { color: 'beginner', label: t('card.session-difficulty.beginner.label', 'Beginner') },
    Intermediate: { color: 'intermediate', label: t('card.session-difficulty.intermediate.label', 'Intermediate') },
    'Advanced/Professional': {
      color: 'advanced',
      label: t('card.session-difficulty.advanced.label', 'Advanced/Professional'),
    },
    All: { color: 'alllevels', label: t('card.session-difficulty.all.label', 'All Levels') },
  }

  if (!difficulties[sessionDifficulty]) {
    return null
  }

  return (
    <Badge
      flavor={difficulties[sessionDifficulty].color}
      label={difficulties[sessionDifficulty].label}
      data-testid="badge-difficulty"
    />
  )
}

export const EnvironmentBadge = () => {
  const { [FLAGS.PREVIEW]: previewFlag } = useFlagsContext()
  const environments = {
    beta: { flavor: 'silver', label: 'Beta' },
    integration: { flavor: 'bronze', label: 'Integration' },
    // we don't want to show this is prod
  }
  const env = getEnv()

  if (!environments[env] && !previewFlag.isOn) {
    return null
  }

  return (
    <>
      {previewFlag.isOn && <Badge flavor="gold" label="Preview" data-testid="badge-preview" />}
      {environments[env] && (
        <Badge flavor={environments[env].flavor} label={environments[env].label} data-testid="badge-environment" />
      )}
    </>
  )
}
