import { useFeatureFlags, useFeatureFlagsWithPrefs } from '@fs/zion-flags'
import React, { createContext, useContext, useMemo } from 'react'
import { FLAGS, flagsConfig } from '../../config'

const FlagsContext = createContext()
export const useFlagsContext = () => useContext(FlagsContext)

export const RootstechFlagsProvider = ({ children }) => {
  const { flagsWithPrefs, flagsWithoutPrefs } = flagsConfig
  const [flags, prefs] = flagsWithPrefs.reduce(
    (acc, { flag, pref }) => {
      acc[0].push(flag)
      acc[1].push(pref)
      return acc
    },
    [[], []]
  )
  const { data: featureFlagsWithPrefs, loading: featureFlagsWithPrefsLoading } = useFeatureFlagsWithPrefs(flags, null, {
    userPreferences: prefs,
  })
  const featureFlags = useFeatureFlags(flagsWithoutPrefs)

  const combinedFlags = useMemo(
    () => ({ ...featureFlagsWithPrefs, ...featureFlags }),
    [featureFlagsWithPrefs, featureFlags]
  )
  // if the prefs flags are undefined (such as if it is loading), send an empty object to prevent type errors
  if (!featureFlagsWithPrefs) {
    flags.forEach((flag) => {
      combinedFlags[flag] = {}
    })
  }

  // make sure every flag is there and defined
  const missingFlags = Object.values(FLAGS).filter((flagName) => !combinedFlags[flagName])
  try {
    if (missingFlags.length) {
      throw new Error('Missing flags')
    }
  } catch (e) {
    console.error(e, missingFlags)
    // stub out any missing flags
    missingFlags.forEach((flag) => {
      combinedFlags[flag] = {}
    })
  }

  const value = useMemo(
    () => ({ ...combinedFlags, loading: featureFlagsWithPrefsLoading }),
    [combinedFlags, featureFlagsWithPrefsLoading]
  )

  return <FlagsContext.Provider value={value}>{children}</FlagsContext.Provider>
}
