const expoHallConfig = {
  locales: ['en', 'de', 'fr', 'it'],
  searchTerms: [
    'Charts',
    'DNA',
    'Professionals',
    'Storytelling',
    'Travel',
    'Records',
    'Research',
    'Preservation',
    'Family History',
    'Technology',
    'Connection',
    'Tree Chart',
    'Storytelling',
    'Search Family',
    'Society',
    'Association',
    'Library',
  ],
  promotionsPath: '/online-expo-hall',
  sponsorsWithDisclaimers: ['0000017c-c7b2-d348-af7c-c7f634960000'],
}
const boothConfig = {
  platinumSponsors: ['ancestry', 'familysearch', 'myheritage', 'storied'],
}

export { expoHallConfig, boothConfig }
