import { useLocation } from '@fs/zion-router'
import { useEffect, useState } from 'react'
import { usabillaConfig } from '../../../config'

const getFeedbackUrl = (path) => {
  return usabillaConfig.options.find((option) => {
    if (option.whiteList) {
      return option.whiteList.some((url) => {
        return new RegExp(url).test(path)
      })
    }
    // use the default form
    return true
  }).form
}

export const useFeedback = () => {
  const location = useLocation()
  const [feedbackUrl, setFeedbackUrl] = useState('')

  useEffect(() => {
    setFeedbackUrl(getFeedbackUrl(location.pathname))
  }, [location.pathname])

  return { feedbackUrl }
}
