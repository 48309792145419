import React from 'react'
import Head from '@fs/zion-head'

const StructuredDataHorizontalCardGrid = ({ data }) => {
  if (!data) return null

  return (
    <Head>
      <script type="application/ld+json" data-testid="carousel-structured-data">
        {JSON.stringify(data)}
      </script>
    </Head>
  )
}

export default StructuredDataHorizontalCardGrid
