import React, { createContext, useContext, useMemo, useState } from 'react'

const CountryContext = createContext()
export const useCountryChange = () => useContext(CountryContext)

export const CountryProvider = ({ children }) => {
  const [country, setCountry] = useState()

  const value = useMemo(() => ({ country, setCountry }), [country])
  return <CountryContext.Provider value={value}>{children}</CountryContext.Provider>
}
