import React, { lazy, Suspense, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import { Trans } from 'react-i18next'
import { Skeleton } from '@fs/zion-ui'
import zionDebug from '@fs/zion-debug'
import WebinarPlayer from '../WebinarPlayer'
import ErrorPlaceholder from '../ErrorPlaceholder'
import VideoCountdown from '../VideoCountdown'
import VideoProcessing from '../VideoProcessing'
import VideoPlayerFallback from '../VideoPlayerFallback'
import { getBrightcoveIds } from './helpers'
import { useWatchEvent, useError } from './hooks'
import { second } from '../../../config'

const debug = zionDebug('rootstech:video')

const YoutubePlayer = lazy(() => import('../YoutubePlayer'))
const BrightcovePlayer = lazy(() => import('../BrightcovePlayer'))

const SUPPORTED_PROVIDERS = ['youtube', 'brightcove', 'WEBINAR', 'countdown']

const LoadingVideo = () => {
  return (
    <div style={{ aspectRatio: '16 / 9' }}>
      <Skeleton.Image height="100%" width="100%" />
    </div>
  )
}

export default function VideoProvider({
  autoplay,
  videoProvider,
  seekStartTime,
  title,
  thumbnail,
  disableControls,
  onEnd,
  loading,
  countdownStartTime,
  isLive,
  sessionId,
  contentLocale,
  videoStartTime,
}) {
  const [isError, onError] = useError(videoProvider)

  const videoProviderType = videoProvider?.providerType // getVideoProvider(videoProvider, isLive)
  const { videoId: brightcoveVideoId, accountId: brightcoveAccountId } = getBrightcoveIds(videoProvider, isLive)
  const videoId = videoProvider?.videoId

  const { trackHistoryEvent, setVideoPlayer } = useWatchEvent({
    videoProviderType,
    sessionId,
    startTime: videoStartTime / second,
  })

  useEffect(() => {
    if (videoProviderType === 'brightcove') {
      debug('video ID:', brightcoveVideoId)
      debug('account ID:', brightcoveAccountId)
      if (!brightcoveVideoId?.match(/[0-9]{13}/)) {
        onError({ message: `Invalid brightcove video ID: ${brightcoveVideoId}` })
      }
      if (!brightcoveAccountId?.match(/[0-9]{13}/)) {
        onError({ message: `Invalid brightcove account ID: ${brightcoveAccountId}` })
      }
    }
    if (videoProviderType === 'youtube' && !videoId?.match(/[-0-9a-z_]{11}/i)) {
      debug('video ID:', videoId)
      onError({ message: `Invalid youtube video ID: ${videoId}` })
    }
  }, [brightcoveAccountId, brightcoveVideoId, onError, videoId, videoProviderType])

  const onVideoEnd = useCallback(
    (event) => {
      trackHistoryEvent(event, true)
      onEnd?.()
    },
    [trackHistoryEvent, onEnd]
  )
  const onVideoPause = useCallback(
    (event) => {
      trackHistoryEvent(event)
    },
    [trackHistoryEvent]
  )
  const onReady = useCallback(
    (player) => {
      setVideoPlayer(player)
    },
    [setVideoPlayer]
  )
  const onBitmovinError = useCallback(
    (error) => {
      onError({ error, message: `Internal error from the Bitmovin player: ${title}` })
    },
    [onError, title]
  )
  const onYoutubeError = useCallback(
    (error) => {
      // eslint-disable-next-line no-console -- this is a valid use
      console.info(
        'Check the Youtube Player API - onError docs for more details: https://developers.google.com/youtube/iframe_api_reference#Events'
      )
      onError({ error, message: `Internal error from the Youtube player: ${title}` })
    },
    [onError, title]
  )

  if (loading) {
    return <LoadingVideo />
  }
  if (isError) {
    return <ErrorPlaceholder />
  }
  if (videoProviderType === 'brightcove') {
    return (
      <div data-testid="video-player">
        <Suspense fallback={<span />}>
          <BrightcovePlayer
            autoplay={autoplay}
            contentLocale={contentLocale}
            seekStartTime={seekStartTime}
            videoId={brightcoveVideoId}
            accountId={brightcoveAccountId}
            title={title}
            thumbnail={thumbnail}
            onError={onBitmovinError}
            onEnd={onVideoEnd}
            onPause={onVideoPause}
            onStart={onReady}
          />
        </Suspense>
      </div>
    )
  }
  if (videoProviderType === 'youtube') {
    return (
      <div data-testid="video-player">
        <Suspense fallback={<span />}>
          <YoutubePlayer
            videoId={videoId}
            title={title}
            autoplay={autoplay}
            start={seekStartTime}
            disableControls={disableControls}
            onError={onYoutubeError}
            onEnd={onVideoEnd}
            onPause={onVideoPause}
            onReady={onReady}
            isLive={isLive}
          />
        </Suspense>
      </div>
    )
  }
  if (videoProviderType?.toLowerCase() === 'webinar') {
    return <WebinarPlayer thumbnail={thumbnail} url={videoProvider?.url?.trim()} />
  }
  if (videoProviderType === 'processing') {
    return <VideoProcessing />
  }
  if (videoProviderType === 'countdown') {
    return <VideoCountdown startTimestamp={countdownStartTime} />
  }

  return (
    <VideoPlayerFallback>
      <Trans i18nKey="home.video-fallback.heading" defaults="You can browse videos in the on-demand section" />
    </VideoPlayerFallback>
  )
}

VideoProvider.propTypes = {
  videoProvider: PropTypes.shape({
    /** The Content Video Provider */
    providerType: PropTypes.oneOf(SUPPORTED_PROVIDERS),
    /** Brightcove and Youtube use this */
    videoId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    /** Only used for Brightcove */
    accountId: PropTypes.string,
    /** Webinar uses this */
    url: PropTypes.string,
    /** Brightcove player thumbnail */
  }),
  /** HTML title for the content */
  title: PropTypes.string,
  /** Seeks the start time for the video */
  seekStartTime: PropTypes.number,
  /** The Placeholder Thumbnail for Brightcove or Webinar players */
  thumbnail: PropTypes.string,
}
