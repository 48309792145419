import PropTypes from 'prop-types'
import React from 'react'
import { Row, Separator } from '@fs/zion-ui'
import { useHistory } from '@fs/zion-router'
import { Arrow } from '@fs/zion-icon'
import { useTranslation } from 'react-i18next'
import { PillButton } from '../buttons'

export default function BackButtonRow({ backText, children, ...props }) {
  const [t] = useTranslation()
  const history = useHistory()
  return (
    <>
      <Separator size="xxs" />
      <Row alignY="middle" {...props}>
        <div growX>
          {history.length > 1 && (
            <PillButton
              linkName="Back"
              onClick={history.goBack}
              Icon={Arrow}
              iconDirection="backward"
              data-testid="back-button"
              emphasis="low"
              size="sm"
            >
              {backText || t('common-ui:back.action', 'Back')}
            </PillButton>
          )}
        </div>
        {children}
      </Row>
      <Separator size="xxs" />
    </>
  )
}

BackButtonRow.propTypes = {
  backText: PropTypes.string,
  children: PropTypes.node,
}
