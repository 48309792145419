import React, { Suspense, lazy } from 'react'
import { Divider, Separator, Skeleton, colors } from '@fs/zion-ui'
import Head from '@fs/zion-head'
import ErrorBoundary from '@fs/zion-error-boundary'
import { Global, css } from '@emotion/core'
import { useFeatureFlag } from '@fs/zion-flags'
import { useLocation } from '@fs/zion-router'
import favicon from './assets/favicon.ico'
import { Footer, PageTitle } from './components'
import { useIsMdWidth } from './hooks'
import { ErrorFallback, FeedbackButton, FloatingChatButton } from './lib'
import { useFeedbackContext, useLoggedOutOverlay } from './providers'

const NavBar = lazy(() => import('./components/header/NavBar'))
const AppRoutes = lazy(() => import('./AppRoutes'))
const AppProviders = lazy(() => import('./AppProviders'))
const QAInfoSheets = lazy(() => import('./lib/qa/QAInfoSheets'))
const LoggedOutOverlay = lazy(() => import('./lib/overlays/LoggedOutOverlay'))
/**
 * As of Dec 11, 2023
 *
 * If we want to change fonts, look at:
 * https://github.com/fs-webdev/zion/blob/master/packages/ui-fonts/src/loadFonts.js
 *
 * We would need to basically copy their implementation to get fonts from foundry
 */

const headingOverrideCss = css`
  body {
    --font-families-headings: var(--font-families-body);
  }
`
const InitialSkeleton = () => {
  const isMdWidth = useIsMdWidth()
  return (
    <div styles={{ background: colors.gray00 }}>
      {/* Nav Bar */}
      <Skeleton.Image width="100%" height={72} />
      <Divider />
      {/* Header */}
      <Skeleton.Image height={isMdWidth ? 400 : 680} width="100%" />
    </div>
  )
}

const Favicon = () => {
  return (
    <Head>
      <link rel="icon" href={favicon} />
    </Head>
  )
}

const App = () => {
  const qaOptionsFlag = useFeatureFlag('rootstech_qa_options')
  const { shouldHideGlobalFeedback } = useFeedbackContext()
  const { overlay } = useLoggedOutOverlay()

  const { pathname } = useLocation()
  const backPaths = ['/playlist', '/registered-session/']
  const guestRedirect = backPaths.includes(pathname) ? '/back' : ''

  return (
    <Suspense fallback={<InitialSkeleton />}>
      <Favicon />
      <NavBar />
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <AppRoutes />
      </ErrorBoundary>
      <Separator size="xxl" />
      <FloatingChatButton />
      {qaOptionsFlag.isOn && <QAInfoSheets />}
      {!shouldHideGlobalFeedback && <FeedbackButton />}
      <Footer />

      {(overlay.isOpen || overlay.transitioning) && <LoggedOutOverlay {...overlay} guestRedirect={guestRedirect} />}
    </Suspense>
  )
}

export default function AppContainer() {
  return (
    <>
      <PageTitle />
      <Global styles={headingOverrideCss} />
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <AppProviders>
          <App />
        </AppProviders>
      </ErrorBoundary>
    </>
  )
}
