import React from 'react'
import { Ellipsis, Row, Paragraph, Card, useContainerWidth, Bleed, Separator, MoreLess } from '@fs/zion-ui'
import { Link } from '@fs/zion-router'
import { css } from '@emotion/core'
import VideoThumbnail from './VideoThumbnail'
import SafeRichText from '../../SafeRichText'
import CreatorExhibitorLinks from '../../CreatorExhibitorLinks'
import { getCustomImage, getDuration, getPercentageWatched } from '../../helpers'
import { StrongLink } from '../../link'
import zionHeadingMap from '../zionHeadingMap'
import { MonoText } from '../../type'

const containerCss = css`
  display: flex;
  align-items: center;
`

const ThumbnailContainer = ({ shouldWrap, to, children, shrinkThumbnail, ...props }) => {
  if (shouldWrap) {
    return (
      <Bleed css={containerCss} top sides>
        <Link to={to} style={{ width: '100%' }} {...props}>
          {children}
        </Link>
      </Bleed>
    )
  }
  const minimumWidth = shrinkThumbnail ? 178 : 286
  return (
    <div css={containerCss} style={{ width: minimumWidth }}>
      <Card to={to} {...props}>
        <Bleed all>{children}</Bleed>
      </Card>
    </div>
  )
}

const BadgesContainer = ({ badges, timestamp, classroom, shouldWrap }) => {
  return shouldWrap ? (
    <div>
      <Row guttersX="none" alignX="end">
        {timestamp}
        {classroom}
      </Row>
      {badges}
    </div>
  ) : (
    <Row>
      <div growX>{badges} </div>
      <Row guttersX="none" noShrinkX>
        {timestamp}
        {classroom}
      </Row>
    </Row>
  )
}

const Description = ({ expandable, description, lineClamp }) => {
  if (!expandable) {
    return (
      <Ellipsis as="span" lineClamp={lineClamp} noTooltip>
        <SafeRichText as="span" dirtyInput={description} />
      </Ellipsis>
    )
  }
  return (
    <MoreLess>
      <SafeRichText as="span" dirtyInput={description} />
    </MoreLess>
  )
}

export default function CardListItem({
  as = 'h3',
  actions,
  badges,
  classroom,
  creators,
  dataForVideo,
  description,
  exhibitors,
  heading,
  linkName,
  timestamp,
  to,
  expandable,
  additionalContent,
}) {
  const atWidth = useContainerWidth()
  const { comingSoon, contentLocale, linkName: thumbnailLinkName, isSession, shrinkThumbnail } = dataForVideo || {}
  const videoDuration = getDuration(dataForVideo)

  const thumbnail = getCustomImage(dataForVideo)
  const percentageWatched = getPercentageWatched(dataForVideo)
  const hasCreatorsExhibitors = creators?.length > 0 || exhibitors?.length > 0
  const shouldWrap = atWidth({ default: true, lg: false })
  const HeadingTag = zionHeadingMap[as]

  return (
    <Card>
      <Row wrap={shouldWrap} alignX={shouldWrap ? 'center' : undefined}>
        {dataForVideo && (
          <ThumbnailContainer
            shouldWrap={shouldWrap}
            to={to}
            noShrinkX={!shouldWrap}
            growX={shouldWrap}
            linkName={thumbnailLinkName}
            shrinkThumbnail={shrinkThumbnail}
          >
            <VideoThumbnail
              thumbnail={thumbnail}
              duration={videoDuration}
              heading={heading}
              comingSoon={comingSoon}
              contentLocale={contentLocale}
              percentageWatched={percentageWatched}
              isSession={isSession}
            />
          </ThumbnailContainer>
        )}
        <div growX>
          <BadgesContainer badges={badges} timestamp={timestamp} classroom={classroom} shouldWrap={shouldWrap} />
          {(badges || timestamp || classroom) && <Separator size="xxs" />}
          <HeadingTag size="H5" data-testid="list-item-title">
            <MonoText>
              <StrongLink to={to} noUnderline linkName={linkName}>
                {heading}
              </StrongLink>
            </MonoText>
          </HeadingTag>
          <CreatorExhibitorLinks creators={creators} exhibitors={exhibitors} />
          <div>
            {additionalContent}
            <Paragraph size="sm">
              <Description
                expandable={expandable}
                description={description}
                lineClamp={hasCreatorsExhibitors ? 2 : 3}
              />
            </Paragraph>
            <Separator />
            {actions}
            {expandable && <Separator size="nano" />}
          </div>
        </div>
      </Row>
    </Card>
  )
}
