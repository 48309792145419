import React, { Children } from 'react'
import { FlowGrid, useContainerWidth } from '@fs/zion-ui'
import SpeakerCard from './SpeakerCard'
import { SectionHeading } from '../../section-card'

export default function SpeakerCardGrid({ speakers, heading = '', as = 'h3' }) {
  const atWidth = useContainerWidth()
  return speakers ? (
    <div>
      <SectionHeading heading={heading} flavor="none" as={as} />
      <FlowGrid
        gutters={atWidth({ default: 'md', xxl: 'xl' })}
        columnCount={atWidth({ default: 2, sm: 4, xl: 6 })}
        lastRowAlignX="start"
      >
        {Children.toArray(
          speakers.map((s) => <SpeakerCard title={s.title} description={s.description} url={s.url} image={s.image} />)
        )}
      </FlowGrid>
    </div>
  ) : null
}
