import React from 'react'
import '@fs/react-multi-carousel/lib/styles.css'
import { IconButton } from '@fs/zion-ui'
import { ArrowCaret } from '@fs/zion-icon'
import { useTranslation } from 'react-i18next'

const Arrow = ({ direction, onClick }) => {
  const [t] = useTranslation()
  const arrowAriaLabel =
    direction === 'backward' ? t('common-ui:previous.action', 'Previous') : t('common-ui:next.action', 'Next')

  return (
    <div style={{ pointerEvents: 'all' }}>
      <IconButton
        Icon={ArrowCaret}
        emphasis="high"
        size="md"
        title={arrowAriaLabel}
        iconDirection={direction}
        onClick={onClick}
        aria-label={arrowAriaLabel}
        data-testid={`${direction}-arrow-button`}
        color="blue"
      />
    </div>
  )
}

export default Arrow
