import React from 'react'
import { useTranslation } from 'react-i18next'
import { Card, Bleed } from '@fs/zion-ui'
import { trackLink } from '@fs/zion-analytics'
import { i18n } from '@fs/zion-locale'
import { allSponsorsWebp, allSponsorsJpg, allSponsorsMobileJpg } from '../../../images'
import { FLAGS } from '../../../config'
import { useFlagsContext } from '../../../providers'
import { useIsMdWidth } from '../../../hooks'

function SponsorsCard() {
  const [t] = useTranslation()
  const isMdWidth = useIsMdWidth()
  const expoHallPrefix =
    window.location.hostname === 'localhost' ? '/expohall/' : `/${i18n.language}/rootstech/expohall/`
  const { [FLAGS.EXPOHALL]: expoHallFlag } = useFlagsContext()

  return (
    <>
      {expoHallFlag.isOn && (
        <Card>
          <Bleed all>
            <img
              src={isMdWidth ? allSponsorsMobileJpg : allSponsorsWebp || allSponsorsJpg}
              alt={t('sponsors.image', 'Sponsors')}
              style={{ height: '100%', width: '100%' }}
              loading="lazy"
              data-testid="sponsor-logos"
            />
            <LinkMap prefix={expoHallPrefix} isMdWidth={isMdWidth} />
          </Bleed>
        </Card>
      )}
    </>
  )
}

/* eslint-disable */
function LinkMap({ prefix: expoHallPrefix, isMdWidth }) {
  const [t] = useTranslation()

  return <>
    <a
      title={t('sponsors.image.familysearch', 'FamilySearch Booth')}
      aria-label={t('sponsors.image.familysearch', 'FamilySearch Booth')}
      href={`${expoHallPrefix}familysearch`}
      data-link-name="Sponsor: FamilySearch"
      style={isMdWidth ? 
        { position: 'absolute', insetInlineStart: '26.4%', top: '2.8%', width: '47.2%', height: '11.5%' } :
        { position: 'absolute', insetInlineStart: '34%', top: '3.4%', width: '31.7%', height: '14.2%' }}
      onClick={trackLink}
    />
    <a
      title={t('sponsors.image.ancestry', 'Ancestry Booth')}
      aria-label={t('sponsors.image.ancestry', 'Ancestry Booth')}
      href={`${expoHallPrefix}ancestry`}
      data-link-name="Sponsor: Ancestry"
      style={isMdWidth ? 
        { position: 'absolute', insetInlineStart: '6.8%', top: '16.5%', width: '40%', height: '6.8%' } :
        { position: 'absolute', insetInlineStart: '5.9%', top: '23%', width: '26.7%', height: '8.6%' }}
      onClick={trackLink}
    />
    <a
      title={t('sponsors.image.my-heritage', 'My Heritage Booth')}
      aria-label={t('sponsors.image.my-heritage', 'My Heritage Booth')}
      href={`${expoHallPrefix}myheritage`}
      data-link-name="Sponsor: MyHeritage"
      style={isMdWidth ? 
        { position: 'absolute', insetInlineStart: '53.1%', top: '16%', width: '40.2%', height: '6.9%' } :
        { position: 'absolute', insetInlineStart: '36.8%', top: '22%', width: '26.4%', height: '8%' }}
      onClick={trackLink}
    />
    <a
      title={t('sponsors.image.storied', 'Storied Booth')}
      aria-label={t('sponsors.image.storied', 'Storied Booth')}
      href={`${expoHallPrefix}storied`}
      data-link-name="Sponsor: Storied"
      style={isMdWidth ? 
        { position: 'absolute', insetInlineStart: '32.6%', top: '25.3%', width: '34.4%', height: '6.8%' } :
        { position: 'absolute', insetInlineStart: '68.4%', top: '21.6%', width: '22.99%', height: '8.1%' }}
      onClick={trackLink}
    />
    <a
      title={t('sponsors.image.23-and-me', '23andMe Booth')}
      aria-label={t('sponsors.image.23-and-me', '23andMe Booth')}
      href={`${expoHallPrefix}23andme`}
      data-link-name="Sponsor: 23andme"
      style={isMdWidth ? 
        { position: 'absolute', insetInlineStart: '17.5%', top: '36.7%', width: '17%', height: '10%' } :
        { position: 'absolute', insetInlineStart: '8.2%', top: '39.8%', width: '11%', height: '12%' }}
      onClick={trackLink}
    />
    <a
      title={t('sponsors.image.family-tree-dna', 'Family Tree DNA Booth')}
      aria-label={t('sponsors.image.family-tree-dna', 'Family Tree DNA Booth')}
      href={`${expoHallPrefix}familytreedna`}
      data-link-name="Sponsor: FamilyTreeDNA"
      style={isMdWidth ? 
        { position: 'absolute', insetInlineStart: '40.1%', top: '37.1%', width: '17.4%', height: '8.6%' } :
        { position: 'absolute', insetInlineStart: '22.8%', top: '39.8%', width: '12.3%', height: '11%' }}
      onClick={trackLink}
    />
    <a
      title={t('sponsors.image.find-my-past', 'Find My Past')}
      aria-label={t('sponsors.image.find-my-past', 'Find My Past')}
      href={`${expoHallPrefix}findmypast`}
      data-link-name="Sponsor: Find My Past"
      style={isMdWidth ? 
        { position: 'absolute', insetInlineStart: '63.6%', top: '38%', width: '18.5%', height: '8.9%' } :
        { position: 'absolute', insetInlineStart: '38%', top: '40.4%', width: '13.2%', height: '12%' }}
      onClick={trackLink}
    />
    <a
      title={t('sponsors.image.family-tree-maker', 'Family Tree Maker Booth')}
      aria-label={t('sponsors.image.family-tree-maker', 'Family Tree Maker Booth')}
      href={`${expoHallPrefix}family-tree-maker`}
      data-link-name="Sponsor: Family Tree Maker"
      style={isMdWidth ? 
        { position: 'absolute', insetInlineStart: '24.6%', top: '49.7%', width: '12.7%', height: '7%' } :
        { position: 'absolute', insetInlineStart: '58.7%', top: '40.8%', width: '8.5%', height: '9.5%' }}
      onClick={trackLink}
    />
    <a
      title={t('sponsors.image.mylio', 'Mylio Booth')}
      aria-label={t('sponsors.image.mylio', 'Mylio Booth')}
      href={`${expoHallPrefix}mylio`}
      data-link-name="Sponsor: Mylio"
      style={isMdWidth ? 
        { position: 'absolute', insetInlineStart: '42.3%', top: '51%', width: '12.9%', height: '5.1%' } :
        { position: 'absolute', insetInlineStart: '69%', top: '42%', width: '9%', height: '8.5%' }}
      onClick={trackLink}
    />
    <a
      title={t('sponsors.image.goodline', 'Goodline Booth')}
      aria-label={t('sponsors.image.goodline', 'goodline Booth')}
      href={`${expoHallPrefix}the-good-line`}
      data-link-name="Sponsor: Goodline"
      style={isMdWidth ? 
        { position: 'absolute', insetInlineStart: '60.6%', top: '51.5%', width: '14.8%', height: '4.5%' } :
        { position: 'absolute', insetInlineStart: '80.5%', top: '43.5%', width: '9.8%', height: '6%' }}
      onClick={trackLink}
    />
    <a
      title={t('sponsors.image.forever-com', 'Forever.com Booth')}
      aria-label={t('sponsors.image.forever-com', 'Forever.com Booth')}
      href={`${expoHallPrefix}forever-com`}
      data-link-name="Sponsor: Forever.com"
      style={isMdWidth ? 
        { position: 'absolute', insetInlineStart: '10.2%', top: '62.5%', width: '19.7%', height: '3.5%' } :
        { position: 'absolute', insetInlineStart: '16.5%', top: '61.6%', width: '13.5%', height: '4.2%' }}
      onClick={trackLink}
    />
    <a
      title={t('sponsors.image.gedmatch', 'GEDmatch Booth')}
      aria-label={t('sponsors.image.gedmatch', 'GEDmatch Booth')}
      href={`${expoHallPrefix}gedmatch`}
      data-link-name="Sponsor: GEDmatch"
      style={isMdWidth ? 
        { position: 'absolute', insetInlineStart: '33.5%', top: '61.3%', width: '13.2%', height: '6.7%' } :
        { position: 'absolute', insetInlineStart: '32.3%', top: '60%', width: '9%', height: '8.5%' }}
      onClick={trackLink}
    />
    <a
      title={t('sponsors.image.genealogyBank', 'Genealogy Bank Booth')}
      aria-label={t('sponsors.image.genealogyBank', 'Genealogy Bank Booth')}
      href={`${expoHallPrefix}genealogybank`}
      data-link-name="Sponsor: genealogybank"
      style={isMdWidth ? 
        { position: 'absolute', insetInlineStart: '51.5%', top: '62%', width: '23%', height: '5.3%' } :
        { position: 'absolute', insetInlineStart: '44.3%', top: '60.8%', width: '15.5%', height: '6.5%' }}
      onClick={trackLink}
    />
    <a
      title={t('sponsors.image.byu-pathway-worldwide', 'BYU-Pathway Worldwide')}
      aria-label={t('sponsors.image.byu-pathway-worldwide', 'BYU-Pathway Worldwide')}
      href={`${expoHallPrefix}byu-pathway-worldwide`}
      data-link-name="Sponsor: BYU-Pathway Worldwide"
      style={isMdWidth ? 
        { position: 'absolute', insetInlineStart: '78.4%', top: '61.4%', width: '11.2%', height: '6.7%' } :
        { position: 'absolute', insetInlineStart: '62%', top: '59%', width: '7.9%', height: '9.82%' }}
      onClick={trackLink}
    />
    <a
      title={t('sponsors.image.roots-magic', 'RootsMagic, Inc. Booth')}
      aria-label={t('sponsors.image.roots-magic', 'RootsMagic, Inc. Booth')}
      href={`${expoHallPrefix}rootsmagic`}
      data-link-name="Sponsor: RootsMagic, Inc."
      style={isMdWidth ? 
        { position: 'absolute', insetInlineStart: '4.6%', top: '72%', width: '20.2%', height: '3.8%' } :
        { position: 'absolute', insetInlineStart: '71.5%', top: '61.5%', width: '14%', height: '5.2%' }}
      onClick={trackLink}
    />
    <a
      title={t('sponsors.image.SBL-knowledge-services', 'SBL Knowledge Services Booth')}
      aria-label={t('sponsors.image.SBL-knowledge-services', 'SBL Knowledge Services Booth')}
      href={`${expoHallPrefix}sbl-knowledge-services-pvt-ltd`}
      data-link-name="Sponsor: SBL Knowledge Services"
      style={isMdWidth ? 
        { position: 'absolute', insetInlineStart: '29.4%', top: '70%', width: '13.2%', height: '7.4%' } :
        { position: 'absolute', insetInlineStart: '25.4%', top: '69.6%', width: '9.3%', height: '9%' }}
      onClick={trackLink}
    />
    <a
      title={t('sponsors.image.the-family-history-guide-association', 'The Family History Guide Association Booth')}
      aria-label={t('sponsors.image.the-family-history-guide', 'The Family History Guide Association Booth')}
      href={`${expoHallPrefix}the-family-history-guide-association`}
      data-link-name="Sponsor: The Family History Guide"
      style={isMdWidth ? 
        { position: 'absolute', insetInlineStart: '47%', top: '71.2%', width: '22.7%', height: '6.5%' } :
        { position: 'absolute', insetInlineStart: '37.2%', top: '70.5%', width: '15.2%', height: '8.1%' }}
      onClick={trackLink}
    />
    <a
      title={t('sponsors.image.myseum', 'Myseum Booth')}
      aria-label={t('sponsors.image.myseum', 'Myseum Booth')}
      href={`${expoHallPrefix}myseum`}
      data-link-name="Sponsor: Myseum"
      style={isMdWidth ? 
        { position: 'absolute', insetInlineStart: '73.6%', top: '71.4%', width: '22%', height: '4%' } :
        { position: 'absolute', insetInlineStart: '54.7%', top: '71.5%', width: '14.9%', height: '5.7%' }}
      onClick={trackLink}
    />
    <a
      title={t('sponsors.image.Genealogical-Society-Pennsylvania', 'Genealogical Society of Pennsylvania Booth')}
      aria-label={t('sponsors.image.Genealogical-Society-Pennsylvania', 'Generalofical Society of Pennsylvania Booth')}
      href={`${expoHallPrefix}genealogical-society-of-pennsylvania`}
      data-link-name="Sponsor: Genealogical Society of Pennsylvania"
      style={isMdWidth ? 
        { position: 'absolute', insetInlineStart: '14.2%', top: '82.9%', width: '16.6%', height: '3.8%' } :
        { position: 'absolute', insetInlineStart: '4.3%', top: '87%', width: '11%', height: '5%' }}
      onClick={trackLink}
    />
    <a
      title={t('sponsors.image.ny-gen-bio-society', 'New York Genealogical and Biographical Society Booth')}
      aria-label={t('sponsors.image.ny-gen-bio-society', 'New York Genealogical and Biographical Society Booth')}
      href={`${expoHallPrefix}new-york-genealogical-and-biographical-society`}
      data-link-name="Sponsor: NY Genealogical & Biographical Society"
      style={isMdWidth ? 
        { position: 'absolute', insetInlineStart: '35%', top: '82.8%', width: '10.6%', height: '4.2%' } :
        { position: 'absolute', insetInlineStart: '16.4%', top: '87%', width: '7.2%', height: '5.8%' }}
      onClick={trackLink}
    />
    <a
      title={t('sponsors.image.association-professional-genealogists', 'Association Professional Genealogists')}
      aria-label={t('sponsors.image.association-professional-genealogists', 'Association Professional Genealogists')}
      href={`${expoHallPrefix}association-of-professional-genealogists`}
      data-link-name="Sponsor: APG"
      style={isMdWidth ? 
        { position: 'absolute', insetInlineStart: '49.7%', top: '82.1%', width: '7.7%', height: '5.6%' } :
        { position: 'absolute', insetInlineStart: '24.8%', top: '86.2%', width: '5.3%', height: '7.3%' }}
      onClick={trackLink}
    />
    <a
      title={t('sponsors.image.connect-my-variant', 'ConnectMyVariant Booth')}
      aria-label={t('sponsors.image.connect-my-variant', 'ConnectMyVariant Booth')}
      href={`${expoHallPrefix}connectmyvariant`}
      data-link-name="Sponsor: connect my variant"
      style={isMdWidth ? 
        { position: 'absolute', insetInlineStart: '60.8%', top: '81.4%', width: '14.2%', height: '6.1%' } :
        { position: 'absolute', insetInlineStart: '30.9%', top: '85.5%', width: '9%', height: '8%' }}
      onClick={trackLink}
    />
    <a
      title={t('sponsors.image.iajgs', 'International Association of Jewish Genealogical Societies Booth')}
      aria-label={t('sponsors.image.iajgs', 'International Association of Jewish Genealogical Societies Booth')}
      href={`${expoHallPrefix}international-association-of-jewish-genealogical-societies`}
      data-link-name="Sponsor: IAJGS"
      style={isMdWidth ? 
        { position: 'absolute', insetInlineStart: '78.1%', top: '82.1%', width: '7.6%', height: '5.9%' } :
        { position: 'absolute', insetInlineStart: '40.6%', top: '85.9%', width: '5%', height: '8%' }}
      onClick={trackLink}
    />
    <a
      title={t('sponsors.image.National-Genealogical-Society', 'National Genealogical Society')}
      aria-label={t('sponsors.image.National-Genealogical-Society', 'National Genealogical Society')}
      href={`${expoHallPrefix}National-Genealogical-Society`}
      data-link-name="Sponsor: National Genealogical Society"
      style={isMdWidth ? 
        { position: 'absolute', insetInlineStart: '8.3%', top: '90.3%', width: '16.7%', height: '4.5%' } :
        { position: 'absolute', insetInlineStart: '46.5%', top: '86.6%', width: '11.3%', height: '6.5%' }}
      onClick={trackLink}
    />
    <a
      title={t('sponsors.image.sons-of-union-veterans', 'Sons Of Union Veterans Booth')}
      aria-label={t('sponsors.image.sons-of-union-veterans', 'Sons Of Union Veterans Booth')}
      href={`${expoHallPrefix}sons-of-union-veterans-of-the-civil-war`}
      data-link-name="Sponsor: sons on union veterans"
      style={isMdWidth ? 
        { position: 'absolute', insetInlineStart: '27.6%', top: '89.4%', width: '15.8%', height: '6.5%' } :
        { position: 'absolute', insetInlineStart: '58%', top: '85.8%', width: '10.3%', height: '8%' }}
      onClick={trackLink}
    />
    <a
      title={t('sponsors.image.sons-of-american-revolution', 'National Society of the Sons of the American Revolution Booth')}
      aria-label={t('sponsors.image.sons-of-american-revolution', 'National Society of the Sons of the American Revolution Booth')}
      href={`${expoHallPrefix}national-society-of-the-sons-of-the-american-revolution`}
      data-link-name="Sponsor: National Society of the Sons of the American Revolution"
      style={isMdWidth ? 
        { position: 'absolute', insetInlineStart: '46.3%', top: '89.5%', width: '8.6%', height: '6%' } :
        { position: 'absolute', insetInlineStart: '68.8%', top: '86.4%', width: '5.8%', height: '7%' }}
      onClick={trackLink}
    />
    <a
      title={t('sponsors.image.church-history-library', 'Church History Library Booth')}
      aria-label={t('sponsors.image.church-history-library', 'Church History Library Booth')}
      href={`${expoHallPrefix}church-history-library`}
      data-link-name="Sponsor: Church History Library"
      style={isMdWidth ? 
        { position: 'absolute', insetInlineStart: '57.8%', top: '90.6%', width: '12.7%', height: '4%' } :
        { position: 'absolute', insetInlineStart: '75%', top: '86.9%', width: '8.5%', height: '5.8%' }}
      onClick={trackLink}
    />
    <a
      title={t('sponsors.image.kentucky-genealogical-society', 'Kentucky Genealogical Society Booth')}
      aria-label={t('sponsors.image.kentucky-genealogical-society', 'Kentucky Genealogical Society Booth')}
      href={`${expoHallPrefix}kentucky-genealogical-society`}
      data-link-name="Sponsor: Kentucky Genealogical Society"
      style={isMdWidth ? 
        { position: 'absolute', insetInlineStart: '73.5%', top: '90.4%', width: '18.2%', height: '4.4%' } :
        { position: 'absolute', insetInlineStart: '84%', top: '87.2%', width: '12%', height: '5.4%' }}
      onClick={trackLink}
    />
  </>;
}
// /* eslint-enable */

export default SponsorsCard
