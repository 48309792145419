import React, { lazy } from 'react'
import { AppLayout, colors } from '@fs/zion-ui'
import { useTranslation } from 'react-i18next'
import { useFeatureFlag } from '@fs/zion-flags'

import { SiteBanner } from './lib'

import ExternalNavigationManager from './components/overlays/ExternalNavigationManager'

const Root = lazy(() => import('@fs/zion-root'))

export default function RootsTechRoot({ children }) {
  const { t } = useTranslation()
  const bannerMaintenanceFlag = useFeatureFlag('rootstech_bannerMaintenance')
  const dayThemeFlag = useFeatureFlag('rootstech_day_theme')
  const theme = dayThemeFlag.isOn ? 'day' : 'night'

  return (
    <Root analytics header={{ hidden: true }} footer={{ hidden: true }} theme={theme}>
      <div id="testing" style={{ flex: 1 }}>
        {bannerMaintenanceFlag.isOn && (
          <SiteBanner
            heading={t(
              'banner-maintenance-message',
              'We are currently experiencing some technical challenges. Some features may not be available.'
            )}
            dismissProps={{ children: t('common-ui:close.action', 'Close') }}
            type="maintenance"
          />
        )}
        <AppLayout fullWidth backgroundColor={colors.gray00}>
          {children}
        </AppLayout>
      </div>
      {/* External Navigation Logic */}
      <ExternalNavigationManager />
    </Root>
  )
}
