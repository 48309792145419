import { colors } from '@fs/zion-ui'
import React from 'react'
import { css } from '@emotion/core'
import { getColors } from './utils'

const textCss = css`
  --c: var(--color, ${colors.gray00});

  // override some paragraph styles
  --special-type-color: var(--c);
  color: var(--c);

  // increase specificity
  && [href] {
    --hc: var(--hover-color, ${colors.gray60});
    border: 0;
    text-decoration: none;
    color: var(--c);

    // maintain zions link hover style
    transition:
      color 0.3s,
      border-color 0.3s;

    &:hover {
      color: var(--hc);
    }

    &:visited {
      color: var(--c);
      &:hover {
        color: var(--hc);
      }
    }
  }
`

/**
 * Makes the text either Black or White based on the Theme. It also removes the default styles (blue, underline, visited) if it's a link.
 *
 * It is supposed to be an evolution of how we used WhiteTheme for headings and can be used in situations that are not linked headings.
 */
export const MonoText = ({ children, complementary }) => {
  const [color, hoverColor] = getColors(complementary)

  return (
    <span css={textCss} style={{ '--color': color, '--hover-color': hoverColor }}>
      {children}
    </span>
  )
}
