import React, { Suspense } from 'react'
import { CHAT_ROUTE_KEY, EmbeddedChat, buildChatRoute } from '@fs/zion-chat'
import ErrorBoundary from '@fs/zion-error-boundary'
import { useFeatureFlag } from '@fs/zion-flags'
import LoadingSpinner from '../LoadingSpinner'
import ErrorFallback from '../ErrorFallback'
import { useAnonCanAddPersonalData } from '../../hooks'

const EmbeddedRTChat = ({ supportChatInformation, onClose }) => {
  const embeddedChatFlag = useFeatureFlag('rootstech_embedded_chat')
  const chatFlag = useFeatureFlag('rootstech_chat')
  const canUsePersonalData = useAnonCanAddPersonalData()
  if (!embeddedChatFlag.isOn || !chatFlag.isOn || !canUsePersonalData) {
    return null
  }

  return (
    // I think the embedded chat is lazy loaded outside of our app
    <Suspense fallback={<LoadingSpinner />}>
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        {/* the embedded chat looks better when wrapped in a div */}
        <div>
          <EmbeddedChat
            supportChatInformation={supportChatInformation}
            chatRoute={buildChatRoute(CHAT_ROUTE_KEY.CREATE_SESSION_CHAT)}
            onClose={onClose}
          />
        </div>
      </ErrorBoundary>
    </Suspense>
  )
}

export default EmbeddedRTChat
