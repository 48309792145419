import React from 'react'
import '@fs/react-multi-carousel/lib/styles.css'
import { colors } from '@fs/zion-ui'
import { css } from '@emotion/core'
import { useTranslation } from 'react-i18next'

const dotCss = css`
  width: var(--dot-diameter);
  height: var(--dot-diameter);
  border-radius: 2em;
  background-color: ${colors.gray50};
  border: none;

  // webkit CSS reset
  padding: 0;

  :focus {
    outline: 2px solid ${colors.gray70};
  }
`
const activeDotCss = css`
  background-color: ${colors.gray90};
`

const Dot = ({ onClick, active, index }) => {
  const [t] = useTranslation()
  return (
    <button
      type="button"
      css={[dotCss, active && activeDotCss]}
      onClick={onClick}
      aria-label={t('carousel.navigation.dot', 'Move the carousel to item {index}', { index: index + 1 })}
    />
  )
}

export default Dot
