import axios from '@fs/zion-axios'
import { i18n } from '@fs/zion-locale'
import SERVICE from './serviceName'

// DOCS: /service/rootstech/registration'

const BASE = `${SERVICE}/registration`
const lang = i18n.language
const config = {
  headers: { 'Accept-Language': lang, 'Content-Language': lang, 'Content-Type': 'application/json' },
  data: {},
}

export async function postRegistration(body) {
  return axios.post(`${BASE}/vendor/rootstech`, body, config)
}
export async function postExpoHallRegistration(body) {
  return axios.post(`${BASE}/vendor/rootstech/expohall`, body, config)
}
export async function postDiscoveryDayRegistration(body) {
  return axios.post(`${BASE}/vendor/rootstech/discovery-day`, body, config)
}

export async function postSubscription(body) {
  return axios.post(`${BASE}/vendor/rootstech/subscribe`, body, config)
}

export async function postEasyRegistration(body) {
  return axios.post(`${BASE}/vendor/rootstech/easy-registration`, body, config)
}

export async function getUsersSubscriptions() {
  return axios.get(`${BASE}/vendor/rootstech/CURRENT/subscriptions-status`, config)
}

export async function updateUserPreferences(body) {
  return axios.put(`${BASE}/vendor/rootstech/preferences`, body, config)
}
