import { useEffect, useState, useCallback } from 'react'
import * as Sentry from '@sentry/react'
import { i18n } from '@fs/zion-locale'

export default function useError(videoProvider) {
  const [isError, setIsError] = useState()

  const onError = useCallback(
    ({ error, message }) => {
      setIsError(true)
      const errorMessage = message || 'Something went wrong with the VideoProvider'
      // this console.error is for logging in Sentry, it doesn't handle objects very well
      console.error(
        'message:',
        errorMessage,
        '\nerror:',
        error,
        '\nvideo data:',
        JSON.stringify({ locale: i18n.language, videoProvider })
      )
      const customError = new Error(errorMessage, { error })
      Sentry.captureException(customError)
    },
    [videoProvider]
  )

  useEffect(() => {
    let isCurrent = true
    isCurrent && setIsError(false)

    return () => {
      isCurrent = false
    }
  }, [videoProvider])

  return [isError, onError]
}
